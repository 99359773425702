import { ethers, providers } from "ethers"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { useCallback, useEffect, useReducer, useState } from "react"
import { walletMessage } from "../../utils/enum/constants"
import Web3Modal from "web3modal"
import {
	fashionIndexApi,
	fashionIndexWeb3AuthApi,
} from "../../utils/fashion-index/api"
import { walletAddressAtom } from "../../services/fashion-index/inventoryServices"
import { useAtom, useSetAtom } from "jotai"
import { AuthResponse, Web3AuthParams } from "../types/web3auth/Web3AuthTypes"
import { AxiosError } from "axios"
import { isWeb3AuthenticatedAtom } from "../../services/fashion-index/AuthServices"

const INFURA_ID = "a6270a3a3d6c43f78ae8f99b86c16504"
const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider, // required
		options: {
			infuraId: INFURA_ID, // required
		},
	},
}

let web3Modal
if (typeof window !== "undefined") {
	web3Modal = new Web3Modal({
		network: "mainnet", // optional
		cacheProvider: true,
		providerOptions, // required
	})
}

type StateType = {
	provider?: any
	web3Provider?: any
	address?: string
	chainId?: number
}

type ActionType =
	| {
			type: "SET_WEB3_PROVIDER"
			provider?: StateType["provider"]
			web3Provider?: StateType["web3Provider"]
			address?: StateType["address"]
			chainId?: StateType["chainId"]
	  }
	| {
			type: "SET_ADDRESS"
			address?: StateType["address"]
	  }
	| {
			type: "SET_CHAIN_ID"
			chainId?: StateType["chainId"]
	  }
	| {
			type: "RESET_WEB3_PROVIDER"
	  }

const initialState: StateType = {
	provider: null,
	web3Provider: null,
	address: null,
	chainId: null,
}

function reducer(state: StateType, action: ActionType): StateType {
	switch (action.type) {
		case "SET_WEB3_PROVIDER":
			return {
				...state,
				provider: action.provider,
				web3Provider: action.web3Provider,
				address: action.address,
				chainId: action.chainId,
			}
		case "SET_ADDRESS":
			return {
				...state,
				address: action.address,
			}
		case "SET_CHAIN_ID":
			return {
				...state,
				chainId: action.chainId,
			}
		case "RESET_WEB3_PROVIDER":
			return initialState
		default:
			throw new Error()
	}
}

function useWallet() {
	const [equipment, setEquipment] = useState(null)
	const [dynamicEquipment, setDynamicEquipment] = useState(null)
	const [equipmentSignature, setEquipmentSignature] = useState(null)

	const [account, setAccount] = useState<string>(null)
	const [connection, setConnection] = useState(null)
	const [isSigning, setIsSigning] = useState(false)
	const [hasSigned, setHasSigned] = useState(false)
	const [isGettingEquipment, setIsGettingEquipment] = useState(false)
	const [isAddressOwnerOf, setIsAddressOwnerOf] = useState(false)
	const [isCheckingOwnerOf, setIsCheckingOwnerOf] = useState(false)
	const setIsWeb3Authenticated = useSetAtom(isWeb3AuthenticatedAtom)
	const { APP_URI } = process.env

	async function signToEarlyAccess() {
		const authData = await fetch(`/api/web3auth/auth?address=${account}`)
		const user = await authData.json()
		const provider = new ethers.providers.Web3Provider(connection)
		const signer = provider.getSigner()

		const signature = await signer.signMessage(
			walletMessage + user.nonce.toString(),
		)
		const isAllowed = await fetch(
			`/api/earlyaccess/getIsEarlyAccess?&account=${account}&signature=${signature}`,
		)
			.then((response) => response.json())
			.then((json) => json.data)

		return isAllowed
	}

	const authenticateWeb3User = async ({
		walletAddress,
		signature,
		nonce,
	}: Web3AuthParams): Promise<string> => {
		try {
			const response = await fashionIndexWeb3AuthApi.post<AuthResponse>(
				"authenticate_web3_user/",
				{
					wallet_address: walletAddress,
					signature,
					nonce,
				},
			)

			const { token } = response.data

			console.log(token)

			// Assuming you have a setup to manage and store authentication tokens.
			// E.g., a React context or another state management solution.
			// setAuthToken(token); // This would be a function provided by your auth context.

			fashionIndexApi.defaults.headers.common["Authorization"] =
				`Bearer ${token}`

			setIsWeb3Authenticated(true)
			return token
		} catch (error) {
			const axiosError = error as AxiosError
			console.error(
				"Authentication failed:",
				axiosError.response?.data || axiosError.message,
			)
			throw axiosError // Optionally, you might want to handle this differently or return a default value.
		}
	}

	async function signToAuthenticateWalletLegacy() {
		try {
			const nonce = await fashionIndexWeb3AuthApi
				.get(`get_nonce/${account}/`)
				.then((response) => response.data.nonce)
			const provider = new ethers.providers.Web3Provider(connection)

			setIsSigning(true)
			const signer = provider.getSigner()
			const signature = await signer.signMessage(`${walletMessage}${nonce}`)
			const params: Web3AuthParams = {
				walletAddress: account,
				signature: signature,
				nonce: nonce,
			}
			const web3AccessToken = await authenticateWeb3User(params)
			setIsSigning(false)
		} catch (error) {
			setIsSigning(false)
		}
	}

	async function signToAuthCloset(hapeTokenId: number) {
		const authData = await fetch(`/api/web3auth/auth?address=${account}`)
		const user = await authData.json()
		const provider = new ethers.providers.Web3Provider(connection)
		const signer = provider.getSigner()

		setIsSigning(true)
		const signature = await signer.signMessage(
			walletMessage + user.nonce.toString(),
		)
		setIsSigning(false)
		setHasSigned(true)
		setIsGettingEquipment(true)
		setEquipmentSignature(signature)

		const equipment = await fetch(
			`/api/inventory/dynamic/getDynamicEquipment?hapePrimeTokenId=${hapeTokenId}&account=${account}`,
			{
				method: "GET",
			},
		)
			.then((response) => response.json())
			.then((json) => json.data)
		setEquipment(equipment)

		setIsGettingEquipment(false)
	}

	async function signToGetEquipment(hapeTokenId: number) {
		const authData = await fetch(`/api/web3auth/auth?address=${account}`)
		const user = await authData.json()
		const provider = new ethers.providers.Web3Provider(connection)
		const signer = provider.getSigner()

		setIsSigning(true)
		const signature = await signer.signMessage(
			walletMessage + user.nonce.toString(),
		)
		setIsSigning(false)
		setHasSigned(true)
		setIsGettingEquipment(true)
		setEquipmentSignature(signature)

		const equipment = await fetch(
			`/api/inventory/getInventory?hapePrimeTokenId=${hapeTokenId}&account=${account}&signature=${signature}`,
		)
			.then((response) => response.json())
			.then((json) => json.data)
		setEquipment(equipment)

		setIsGettingEquipment(false)
	}

	async function signToGetDynamicEquipment(hapeTokenId: number) {
		try {
			let signature
			if (process.env.NEXT_PUBLIC_IS_TESTING !== "true") {
				const authData = await fetch(`/api/web3auth/auth?address=${account}`)
				const user = await authData.json()
				const provider = new ethers.providers.Web3Provider(connection)
				const signer = provider.getSigner()

				setIsSigning(true)

				signature = await signer.signMessage(
					walletMessage + user.nonce.toString(),
				)
				setIsSigning(false)
				setHasSigned(true)
				setIsGettingEquipment(true)
				setEquipmentSignature(signature)
			} else {
				setIsGettingEquipment(true)
			}
			//const inventoryForHape = await getInventoryForHape(account, hapeTokenId)
			const equipment = await fetch(
				`/api/inventory/dynamic/getDynamicEquipment?hapePrimeTokenId=${hapeTokenId}&account=${account}&signature=${signature}`,
			)
				.then((response) => response.json())
				.then((json) => json.data)
			setEquipment(equipment)
			//setDynamicEquipment(inventoryItems)

			setIsGettingEquipment(false)
		} catch (e) {
			setIsSigning(false)
		}
	}

	function unSign() {
		setHasSigned(false)
		setIsGettingEquipment(false)
		setEquipmentSignature(null)
		setEquipment(null)
	}

	const [state, dispatch] = useReducer(reducer, initialState)
	const { provider, web3Provider } = state

	const connect = useCallback(async function () {
		// This is the initial `provider` that is returned when
		// using web3Modal to connect. Can be MetaMask or WalletConnect.
		const provider = await web3Modal.connect()

		// We plug the initial `provider` into ethers.js and get back
		// a Web3Provider. This will add on methods from ethers.js and
		// event listeners such as `.on()` will be different.
		const web3Provider = new providers.Web3Provider(provider)

		const signer = web3Provider.getSigner()
		const address = await signer.getAddress()
		setAccount(address)

		const network = await web3Provider.getNetwork()

		setConnection(provider)

		dispatch({
			type: "SET_WEB3_PROVIDER",
			provider,
			web3Provider,
			address,
			chainId: network.chainId,
		})
	}, [])

	const checkOwnerOf = async (hapeTokenId) => {
		setIsCheckingOwnerOf(true)
		if (process.env.NEXT_PUBLIC_IS_TESTING === "false") {
			const hapeOwner = await fetch(
				`/api/web3auth/getHapeOwner?hape_token_id=${hapeTokenId}`,
			).then((response) => response.json())

			setIsAddressOwnerOf(
				hapeOwner.data.toLowerCase() === account.toLowerCase(),
			)
		} else {
			setIsAddressOwnerOf(true)
		}

		setIsCheckingOwnerOf(false)
	}

	const resetWeb3AuthenticationCredentials = () => {
		setIsWeb3Authenticated(false)
		delete fashionIndexApi.defaults.headers.common["Authorization"]
	}

	const disconnect = useCallback(
		async function () {
			setAccount("")
			setEquipment(null)
			setIsGettingEquipment(false)
			setIsSigning(false)
			setHasSigned(false)

			resetWeb3AuthenticationCredentials()

			await web3Modal.clearCachedProvider()
			if (provider?.disconnect && typeof provider.disconnect === "function") {
				await provider.disconnect()
			}
			dispatch({
				type: "RESET_WEB3_PROVIDER",
			})
		},
		[provider],
	)

	// A `provider` should come with EIP-1193 events. We'll listen for those events
	// here so that when a user switches accounts or networks, we can update the
	// local React state with that new information.
	useEffect(() => {
		if (provider?.on) {
			const handleAccountsChanged = (accounts) => {
				// eslint-disable-next-line no-console
				console.log("accountsChanged", accounts)

				resetWeb3AuthenticationCredentials()

				setAccount(accounts[0])
				dispatch({
					type: "SET_ADDRESS",
					address: accounts[0],
				})
			}

			// https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
			const handleChainChanged = (_hexChainId) => {
				resetWeb3AuthenticationCredentials()

				window.location.reload()
			}

			const handleDisconnect = (error) => {
				disconnect()
			}

			provider.on("accountsChanged", handleAccountsChanged)
			provider.on("chainChanged", handleChainChanged)
			provider.on("disconnect", handleDisconnect)

			// Subscription Cleanup
			return () => {
				if (provider.removeListener) {
					provider.removeListener("accountsChanged", handleAccountsChanged)
					provider.removeListener("chainChanged", handleChainChanged)
					provider.removeListener("disconnect", handleDisconnect)
				}
			}
		}
	}, [provider, disconnect])

	const getFormattedAddress = () => {
		if (account) {
			return (
				account.substring(0, 6) +
				"..." +
				account.substring(account.length - 4, account.length)
			)
		}
	}

	return {
		web3Provider,
		equipment,
		equipmentSignature,
		connect,
		disconnect,
		signToGetEquipment,
		signToEarlyAccess,
		getFormattedAddress,
		checkOwnerOf,
		isSigning,
		hasSigned,
		unSign,
		isGettingEquipment,
		isAddressOwnerOf,
		isCheckingOwnerOf,
		account,
		signToGetDynamicEquipment,
		dynamicEquipment,
		signToAuthenticateWalletLegacy,
	}
}

export default useWallet
