import { useCallback, useState } from "react"
import {
	getHapeGradient,
	getHapeGradientHelper,
} from "../../utils/hape/hapeHelper"

function useModal() {
	const [appliedSortBy, setAppliedSortBy] = useState(null)
	const [hapeColor, setHapeColor] = useState(null)
	const [hapeModalGradient, setHapeModalGradient] = useState<string>(null)
	const [badReset, setBadReset] = useState(0)
	const [currentHapeTokenId, setCurrentHapeTokenId] = useState(null)

	const resetModalColors = () => {
		setHapeModalGradient("")
		setHapeColor("")
	}

	const setModalColorsAsNative = useCallback((card: any) => {
		// Assuming getHapeGradient and getHapeGradientHelper are defined elsewhere
		const linearGradient = getHapeGradient(card)
		setHapeModalGradient(linearGradient)

		const color = getHapeGradientHelper(card).horizontal.coord_1
		setHapeColor(color)
	}, [])

	const setModalColorsAsCny2024 = useCallback(() => {
		const primaryCnyRed = "rgba(217,10,26,.85)"
		const secondaryCnyRed = "rgba(107, 2, 10,1)"
		const lighterCnyRed = "rgba(217,10,26,.4)"
		const linearGradient = `linear-gradient(${"315deg"},${primaryCnyRed}, ${secondaryCnyRed}, ${lighterCnyRed})`
		setHapeModalGradient(linearGradient)
		const filterBarColor = "rgba(122,0,9,1)"
		setHapeColor(filterBarColor)
	}, [])

	return {
		appliedSortBy,
		setAppliedSortBy,
		hapeColor,
		setHapeColor,
		hapeModalGradient,
		setHapeModalGradient,
		badReset,
		setBadReset,
		currentHapeTokenId,
		setCurrentHapeTokenId,
		setModalColorsAsNative,
		setModalColorsAsCny2024,
		resetModalColors,
	}
}

export default useModal
