import React, { createContext, Dispatch, SetStateAction } from "react"
import useDarkMode from "../hooks/useDarkMode"

interface IDarkModeContext {
	isDarkModeEnabled: boolean
	toggleDarkMode?: () => void
}

const defaultState = {
	isDarkModeEnabled: false
}

const DarkModeContext = createContext<IDarkModeContext>(defaultState)

function DarkModeProvider({ children }) {
	return (
		<DarkModeContext.Provider value={useDarkMode()}>
			{children}
		</DarkModeContext.Provider>
	)
}

export { DarkModeContext, DarkModeProvider }
