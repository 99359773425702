import { Trait, GeneratorTrait, MinimalTrait } from "../../src/types"
import { RawTrait } from "../../src/types/fashionIndex/InventoryTypes"
import { dynamicClothingThumbnailsUri } from "../enum/constants"
import { ClothingMapping, DuotoneMapping } from "./clothingMapping"

export const allWearableCategories = [
	"Accessory",
	"Clothing",
	"Earrings",
	"Eyewear",
	"Gadget",
	"Headwear",
	"Jewellery",
	"Nose Piercing",
	"Teeth",
	"Special",
	"Wristband",
]

export const itemTitleImgSizing = {
	Headwear: {
		backgroundSize: "40%",
		backgroundPositionX: "-3%",
		backgroundPositionY: "55%",
	},
	Eyewear: {
		backgroundSize: "54%",
		backgroundPositionX: "-29%",
		backgroundPositionY: "52%",
	},
	Jewellery: {
		backgroundPositionX: "-22%",
		backgroundPositionY: "63%",
	},
	Clothing: {
		backgroundSize: "68%",
		backgroundPositionX: "-46%",
		backgroundPositionY: "17%",
	},
	Wristband: {
		backgroundSize: "35%",
		backgroundPositionX: "0%",
		backgroundPositionY: "45%",
	},
}

// 6082 removed shoa's
const handmadeTokens = [
	217, 546, 650, 664, 747, 831, 873, 996, 1141, 1217, 1254, 1268, 1389, 1474,
	1642, 1714, 1784, 2020, 2282, 2311, 2547, 2594, 3220, 3789, 3942, 4226, 4280,
	4447, 4556, 4883, 5622, 5856, 5952, 5981, 6003, 6127, 6475, 6493, 6688, 6808,
	6827, 7758, 7808, 7924,
]

const femaleHapeTokenIds = [
	548, 1624, 3788, 3019, 3773, 4143, 1022, 790, 463, 4109, 2187, 6292, 5592,
	359, 1300, 1305, 20, 7516, 6753, 917, 3206, 6733, 6728, 7659, 5427, 1553, 89,
	5596, 6772, 6677, 4598, 6744, 3221, 302, 7379, 6711, 3972, 2846, 6583, 512,
	4899, 4938, 4390, 6336, 3791, 4170, 339, 5151, 5921, 1002, 6617, 4456, 2047,
	6596, 6375, 1936, 8063, 6804, 507, 6047, 518, 7867, 2972, 4088, 6097, 8009,
	785, 6505, 2782, 2011, 3980, 986, 469, 7714, 478, 6603, 8064, 5569, 3983,
	6244, 3184, 1015, 1513, 6353, 5673, 1109, 7404, 3520, 6880, 6091, 8141, 6662,
	6607, 4214, 6368, 1733, 5156, 5667, 7792, 4669, 17, 1837, 6976, 816, 2856,
	973, 2112, 1085, 6138, 2012, 6539, 4220, 519, 7232, 7106, 4001, 6843, 7856,
	3189, 3397, 3427, 7546, 433, 2985, 7398, 4842, 4383, 1617, 6221, 7832, 3594,
	1909, 2495, 4127, 6425, 6458, 3984, 2296, 4191, 4045, 3198, 7317, 6315, 7900,
	5365, 295, 3228, 3232, 1652, 1866, 2234, 3202, 6348, 7036, 2278, 3229, 4656,
	4676, 308, 3238, 6414, 3641, 2936, 4810, 6308, 4684, 4780, 5149, 888, 4915,
	7914, 7939, 5218, 7314, 4497, 6950, 2628, 1276, 3405, 3796, 6590, 471, 5197,
	5567, 6714, 2419, 6935, 1928, 3193, 3519, 3839, 1006, 354, 6173, 3687, 1844,
	3908, 2931, 7835, 3222, 1351, 3952, 5121, 3886, 3219, 5348, 7644, 1622, 2508,
	134, 3981, 4099, 6416, 7642, 3938, 7448, 7485, 6171, 5663, 2887, 3924, 6743,
	4692, 205, 64, 5281, 240, 6527, 4455, 3754, 5801, 5308, 398, 6007, 321, 521,
	5424, 4545, 691, 6594, 6433, 683, 1287, 5062, 322, 1174, 426, 6339, 847, 3378,
	5606, 6556, 6112, 425, 2948, 7656, 346, 2808, 5859, 5700, 4224, 466, 7865,
	3988, 249, 4988, 2572, 8083, 3869, 413, 4441, 3477, 2967, 7716, 6916, 2252,
	1332, 5997, 2010, 5574, 306, 5964, 7842, 326, 5455, 658, 4268, 846, 5945, 169,
	318, 11, 2322, 7327, 4576, 7209, 174, 5675, 823, 717, 6225, 3097, 349, 4874,
	1767, 6954, 3766, 4525, 3820, 185, 269, 668, 260, 5668, 916, 948, 3015, 3452,
	4777, 3330, 5529, 3016, 390, 3663, 7677, 6259, 7987, 1062, 2239, 933, 4691,
	6098, 688, 656, 251, 832, 8038, 2493, 2772, 7091, 5399, 7089, 5072, 479, 827,
	6208, 1163, 1003, 4337, 6391, 4035, 250, 739, 1980, 136, 4307, 4322, 4897,
	1732, 3286, 3804, 1549, 6928, 1945, 274, 1372, 2753, 6666, 795, 299, 216,
	4188, 907, 6883, 6497, 1528, 5618, 7181, 3576, 4585, 5406, 5935, 3331, 6383,
	6016, 6981, 129, 5591, 5171, 4770, 7768, 3152, 167, 6807, 5263, 200, 3099,
	1448, 3968, 6151, 2352, 6795, 4165, 3498, 1045, 5183, 4300, 311, 448, 5271,
	2353, 6786, 525, 4834, 1769, 256, 2788, 8001, 8022, 4481, 5974, 2422, 236,
	7225, 5998, 6186, 4758, 1378, 1061, 8072, 2035, 165, 7473, 2108, 7800, 6144,
	3911, 5475, 7988, 6323, 3479, 6305, 4900, 3704, 208, 1855, 3057, 199, 3373,
	366, 1685, 41, 1951, 4957, 605, 4696, 3407, 5658, 866, 3346, 6025, 2500, 3963,
	554, 327, 2586, 8023, 2241, 5213, 5585, 7475, 7139, 6217, 5055, 6066, 2911,
	2226, 1574, 7026, 356, 364, 8046, 7982, 3233, 3851, 4121, 4954, 6967, 3949,
	6069, 1104, 1324, 6731, 1857, 2632, 6248, 930, 7001, 6554, 2483, 3945, 5304,
	6314, 7270, 1124, 4499, 6442, 5326, 2581, 7226, 660, 8090, 3644, 4269, 8189,
	6459, 2525, 6703, 7744, 2873, 1911, 2270, 4000, 371, 4332, 5903, 3166, 4299,
	6004, 7956, 570, 2842, 3090, 4586, 1900, 4916, 6405, 5377, 4878, 111, 1117,
	4593, 4274, 423, 623, 7708, 7661, 2031, 3084, 4287, 4847, 5354, 3271, 7431,
	8049, 842, 5537, 4116, 6775, 1379, 1150, 6547, 2291, 3164, 5479, 1036, 7334,
	6490, 6939, 1516, 3014,
]

type Fix = {
	traitToFix: MinimalTrait
	fix: MinimalTrait
}

// Define the main type for the custom value updates
type HandmadeToDavenatorCustomValueUpdates = {
	[key: number]: Fix[]
}

const handmadeToDavenatorCustomValueUpdates: HandmadeToDavenatorCustomValueUpdates =
	{
		217: [
			{
				traitToFix: {
					category: "Tattoos",
					value: "Bullet Head (Set)",
				},
				fix: {
					category: "Tattoos",
					value: "Bullet Head (Oblivion)",
				},
			},
			{
				traitToFix: {
					category: "Jewellery",
					value: "HAPE Cuban Link (Oblivion)",
				},
				fix: {
					category: "Jewellery",
					value: "HAPE Cuban Link (Oblivion 217)",
				},
			},
		],
		546: [
			{
				traitToFix: {
					category: "Jewellery",
					value: "HAPE Cuban Link (Oblivion)",
				},
				fix: {
					category: "Jewellery",
					value: "HAPE Cuban Link (Oblivion 546)",
				},
			},
		],
		1141: [
			{
				traitToFix: {
					category: "Implants",
					value: "Implants",
				},
				fix: {
					category: "Implants",
					value: "Implants 1141",
				},
			},
		],
		1217: [
			{
				traitToFix: {
					category: "Accessory",
					value: "Backpack",
				},
				fix: {
					category: "Accessory",
					value: "backpack (pacific pink 1217)",
				},
			},
		],
		1254: [
			{
				traitToFix: {
					category: "Earrings",
					value: "Pearl (White)",
				},
				fix: {
					category: "Earrings",
					value: "Pearl (White 1254)",
				},
			},
		],
		2282: [
			{
				traitToFix: {
					category: "Jewellery",
					value: "Large Stud with Diamonds (Platinum)",
				},
				fix: {
					category: "Jewellery",
					value: "Large Stud with Diamonds (Platinum 2282)",
				},
			},
		],
		2311: [
			{
				traitToFix: {
					category: "Accessory",
					value: "Backpack (Red)",
				},
				fix: {
					category: "Accessory",
					value: "Backpack (Red 2311)",
				},
			},
			{
				traitToFix: {
					category: "Head",
					value: "Head",
				},
				fix: {
					category: "Head",
					value: "Head HM",
				},
			},
		],
		5622: [
			{
				traitToFix: {
					category: "Implants",
					value: "Implants",
				},
				fix: {
					category: "Tattoos",
					value: "Implant Eyes",
				},
			},
		],
		5952: [
			{
				traitToFix: {
					category: "Clothing",
					value: "Unzipped Bomber (Pinky Dunes)",
				},
				fix: {
					category: "Clothing",
					value: "Unzipped Bomber (Dark Urban Camo)",
				},
			},
		],
		5981: [
			{
				traitToFix: {
					category: "Head",
					value: "Head",
				},
				fix: {
					category: "Head",
					value: "Unzipped Bomber (Dark Urban Camo)",
				},
			},
		],
		6003: [
			{
				traitToFix: {
					category: "Jewellery",
					value: "Diamond Cuban Link (Platinum)",
				},
				fix: {
					category: "Jewellery",
					value: "Diamond Cuban Link (Platinum 6003)",
				},
			},
		],
		6827: [
			{
				traitToFix: {
					category: "Eyewear",
					value: "Visor (Red)",
				},
				fix: {
					category: "Eyewear",
					value: "Visor (Red 6827)",
				},
			},
		],
		7758: [
			{
				traitToFix: {
					category: "Headwear",
					value: "HB Snapback (Black / White)",
				},
				fix: {
					category: "Headwear",
					value: "HB Snapback (Black / White 7758)",
				},
			},
			{
				traitToFix: {
					category: "Jewellery",
					value: "Long Cuban Link (Silver)",
				},
				fix: {
					category: "Jewellery",
					value: "Long Cuban Link (Silver 7758)",
				},
			},
		],
	}

export const hapeGeneratorUri =
	//"https://xqyg8sllsi.execute-api.us-east-1.amazonaws.com/Prod/hape/"
	"https://85scyec1wa.execute-api.us-east-1.amazonaws.com/Prod/hape/"

export const isHandmade = (hapeTokenId: number) => {
	if (handmadeTokens.includes(hapeTokenId)) {
		return true
	} else {
		return false
	}
}

export const getThumbnailImageUri = (
	category: string,
	value: string,
	isInventoryThumbnail: boolean = false,
) => {
	const defaultSizing = "700x700"

	const imgSize = isInventoryThumbnail ? "160x160" : defaultSizing

	const foundMapping = ClothingMapping.find(
		(c) =>
			c.category.toLowerCase() === category?.toLowerCase() &&
			c.value.toLowerCase() === value?.toLowerCase(),
	)
	if (foundMapping) {
		if (
			tempEquipmentPanelThumbnailTraits.find(
				(t) => t.category === category && t.value === value,
			)
		) {
			return `${dynamicClothingThumbnailsUri}/${imgSize}/fashion-index/equipment-thumbnails/${category}/${foundMapping.mappedValue}`
		} else {
			return `${dynamicClothingThumbnailsUri}/${imgSize}/${foundMapping.mappedCollection}/${foundMapping.mappedValue}`
		}
	} else {
		return `/static/img/dynamic-equipment/placeholders/item-title/${category}.svg`
	}
}

export const addBackground = (
	tokenId: number,
	generatorTraits: GeneratorTrait[],
) => {
	let background = generatorTraits.find((t) => t.trait_type === "Fur").value

	let isDuotone = generatorTraits.find((t) => t.trait_type === "Duotone")
	if (isDuotone) {
		background = DuotoneMapping[tokenId]
	}

	if (backgroundMapping[background.toLowerCase()]) {
		background = backgroundMapping[background.toLowerCase()]
	}
	generatorTraits.push({
		trait_type: "Background",
		value: background,
	})
	return generatorTraits
}

const reconfigureEnhancedBackground = (generatorTraits: GeneratorTrait[]) => {
	const specialIndex = generatorTraits.findIndex(
		(gt) => gt.trait_type === "Special",
	)
	if (specialIndex > -1) {
		generatorTraits[specialIndex].trait_type = "Enhanced Background"
	}

	return generatorTraits
}

const formatForGalleryOrExport = (
	tokenId: number,
	generatorTraits: GeneratorTrait[],
) => {
	let updatedGeneratorTraits = addBackground(tokenId, generatorTraits)
	updatedGeneratorTraits = reconfigureEnhancedBackground(generatorTraits)
	return updatedGeneratorTraits
}

export const processDynamicTraitsForDavenator = (
	traitList: RawTrait[],
	hapeId: string,
	isGalleryOrExportFormat: boolean = false,
) => {
	const hapeIdAsNum = parseInt(hapeId)
	console.log("hapeId error1", hapeId, traitList)
	const processedThroughUpperBodyGenerator = getFormatForUpperBodyGenerator(
		traitList,
		hapeIdAsNum,
	)
	console.log("hapeId error", hapeId, traitList)

	const traitsForLayering = processedThroughUpperBodyGenerator.map((trait) => ({
		category: trait.trait_type,
		value: trait.value,
	}))

	let traitsForDavenator = checkEnhancedBackground(
		processedThroughUpperBodyGenerator,
	)

	if (isGalleryOrExportFormat) {
		traitsForDavenator = {
			...traitsForDavenator,
			traitsFormattedForDavenator: formatForGalleryOrExport(
				hapeIdAsNum,
				traitsForDavenator.traitsFormattedForDavenator,
			),
		}
	}

	return {
		...traitsForDavenator,
		traitsForLayering: traitsForLayering,
	}
}

// export const processDynamicTraitsforExport = (
// 	traitList: RawTrait[],
// 	hapeId: string
// ) => {
// 	// Processed for Export
// 	let exportTraitsForUpperBodyGenerator =
// 		getExportFormatForUpperBodyGeneratorMinimal(traitList, hapeId)

// 	exportTraitsForUpperBodyGenerator = checkEnhancedBackground(
// 		exportTraitsForUpperBodyGenerator
// 	)
// 	exportTraitsForUpperBodyGenerator = handleSpecialTraits(
// 		exportTraitsForUpperBodyGenerator
// 	)
// }

export const checkEnhancedBackground = (traits: GeneratorTrait[]) => {
	const enhancedBackgroundPairings = {
		"Violet Waves Hoodie (DRESSX)": "Reflective Watercolours",
	}
	const enhancedBackgroundIndex = traits.findIndex(
		(t) => t.trait_type === "Enhanced Background",
	)
	const specialIndex = traits.findIndex((t) => t.trait_type === "Special")
	const hasVisorWithLaser = traits.find(
		(t) => t.value.toLowerCase() === "visor with laser",
	)

	const cny24LanternsIndex = traits.findIndex(
		(t) => t.value.toLowerCase() === "cny24 lanterns (fortune red)",
	)

	const clothingValue = traits.find((t) => t.trait_type === "Clothing")?.value
	const enhancedBackgroundPaired = enhancedBackgroundPairings[clothingValue]

	const katanas = ["Katanas (Black)", "Katanas (White)"]

	// If Violet Waves Hoodie + Bananas + Snowboard
	// Generates a hacky variant to handle this (temporary)
	// Else, push the special associated enhanced background
	if (
		traits[specialIndex]?.value === "X23 III.O Snowboard (White)" ||
		(traits[specialIndex]?.value === "X23 III.O Snowboard (White)" &&
			((clothingValue === "Violet Waves Hoodie (DRESSX)" &&
				traits[enhancedBackgroundIndex]?.value === "Bananas" &&
				traits[specialIndex]?.value === "X23 III.O Snowboard (White)") ||
				(enhancedBackgroundPaired &&
					traits[specialIndex]?.value === "X23 III.O Snowboard (White)")))
	) {
		traits.splice(specialIndex, 1)
		traits.push({
			trait_type: "Enhanced Background",
			value: "X23 III.O Snowboard (White)",
		})
	} else if (katanas.includes(traits[specialIndex]?.value)) {
		traits.push({
			trait_type: "Enhanced Background",
			value: traits[specialIndex]?.value,
		})
	} else if (enhancedBackgroundPaired) {
		const newEnhancedBackgroundTrait: GeneratorTrait = {
			trait_type: "Enhanced Background",
			value: enhancedBackgroundPaired,
		}
		traits.push(newEnhancedBackgroundTrait)
	}

	const hapeTraitsFormatted = traits.map((t) => {
		return {
			category: t.trait_type,
			value: t.value,
		}
	})
	// Checks the original Enhanced Background for Bananas
	// Rids it from the Davenator so we handle Bananas in UI only
	const requiresExtension =
		enhancedBackgroundIndex > -1 || hasVisorWithLaser || cny24LanternsIndex > -1

	if (requiresExtension) {
		// If lanterns exist, remove other enhanced backgrounds (like bananas)
		// Else, change the bananas to special so that we handle it in the export function
		if (cny24LanternsIndex > -1 && enhancedBackgroundIndex > -1) {
			traits[enhancedBackgroundIndex].trait_type = "Special-2"
		} else if (traits[enhancedBackgroundIndex]?.value === "Bananas") {
			traits[enhancedBackgroundIndex].trait_type = "Special"
		}
	}

	return {
		traitsFormattedForDavenator: traits,
		hapeTraitsFormatted: hapeTraitsFormatted,
		deactivateBackgrounds: !requiresExtension,
	}
}

const ignoreCategories = ["Birthday", "Heart Number"]
const alternativeNameMapping = {
	"jakxgem bmbr": { alternativeName: "jakxgem bomber jacket" },
	"tfm long-sleeve": { alternativeName: "tfm long sleeve" },
}

export const getFormatForUpperBodyGenerator = (
	traits: Trait[] | RawTrait[],
	tokenId: number,
) => {
	traits = traits.filter((trait) => !ignoreCategories.includes(trait.category))
	let generatorTraits: GeneratorTrait[] = traits.map((trait) => {
		const formattedTrait: GeneratorTrait = {
			trait_type: trait.category,
			value:
				alternativeNameMapping[trait.value.toLowerCase()]?.alternativeName ??
				trait.value,
		}

		return formattedTrait
	})

	generatorTraits = processHapesForGenerator(generatorTraits, tokenId)

	return generatorTraits
}

const backgroundMapping = {
	"baby blue": "baby blue",
	"baby blue leopard": "baby blue",
	blue: "blue",
	brown: "brown",
	"bubble gum": "bubble gum",
	"bubble gum cheetah": "bubble gum",
	champagne: "champagne",
	cheetah: "brown",
	"computer green": "computer green",
	"croc green": "computer green",
	"desert storm": "champagne",
	"geometric urban": "graphite",
	graphite: "graphite",
	illuminating: "illuminating",
	"illuminating spackle": "illuminating",
	lime: "lime",
	"lime stripes": "lime",
	navy: "navy",
	"navy seals": "navy",
	oblivion: "white",
	orange: "orange",
	"pacific pink": "pacific pink",
	"peri tone": "peri tone",
	"peri waves": "peri tone",
	"pinky dunes": "pacific pink",
	pistachio: "pistachio",
	"pistachio leaves": "pistachio",
	red: "red",
	"red camo": "red",
	"tiger style": "orange",
	"ultimate grey": "ultimate grey",
	"ultimate elephant": "ultimate grey",
	"urban camo": "blue",
	"velvet violet": "velvet violet",
	"violent violet": "velvet violet",
	white: "white",
	"white zebra": "white",
	yellow: "yellow",
	"yellow dust": "yellow",
}

export const getExportFormatForUpperBodyGenerator = (
	traits: Trait[],
	tokenId: number,
) => {
	traits = traits.filter((trait) => !ignoreCategories.includes(trait.category))
	let generatorTraits: GeneratorTrait[] = traits.map((trait) => {
		const formattedTrait: GeneratorTrait = {
			trait_type: trait.category,
			value: trait.value,
		}

		return formattedTrait
	})

	generatorTraits = processHapesForGenerator(generatorTraits, tokenId)

	let background = generatorTraits.find((t) => t.trait_type === "Fur").value

	let isDuotone = generatorTraits.find((t) => t.trait_type === "Duotone")
	if (isDuotone) {
		background = DuotoneMapping[tokenId]
	}

	if (backgroundMapping[background.toLowerCase()]) {
		background = backgroundMapping[background.toLowerCase()]
	}

	generatorTraits.push({
		trait_type: "Background",
		value: background,
	})

	const specialIndex = generatorTraits.findIndex(
		(t) => t.trait_type === "Special",
	)
	if (specialIndex > -1) {
		generatorTraits[specialIndex].trait_type = "Enhanced Background"
	}

	return generatorTraits
}

export const getExportFormatForUpperBodyGeneratorMinimal = (
	traits: GeneratorTrait[],
	tokenId: number,
) => {
	let traitsToFormat = getDeepCopy(traits)
	traitsToFormat = traitsToFormat.filter(
		(trait) => !ignoreCategories.includes(trait.trait_type),
	)
	let generatorTraits: GeneratorTrait[] = traitsToFormat.map((trait) => {
		const formattedTrait: GeneratorTrait = {
			trait_type: trait.trait_type,
			value: trait.value,
		}

		return formattedTrait
	})

	generatorTraits = processHapesForGenerator(generatorTraits, tokenId)

	let background = generatorTraits.find((t) => t.trait_type === "Fur").value

	let isDuotone = generatorTraits.find((t) => t.trait_type === "Duotone")
	if (isDuotone) {
		background = DuotoneMapping[tokenId]
	}

	if (backgroundMapping[background.toLowerCase()]) {
		background = backgroundMapping[background.toLowerCase()]
	}
	generatorTraits.push({
		trait_type: "Background",
		value: background,
	})

	return generatorTraits
}

export const handleSpecialTraits = (traits: GeneratorTrait[]) => {
	let generatorTraits = getDeepCopy(traits)

	const specialIndex = generatorTraits.findIndex(
		(t) => t.trait_type === "Special",
	)

	if (specialIndex > -1) {
		generatorTraits[specialIndex].trait_type = "Enhanced Background"
	}
	return generatorTraits
}

export const getFormatForUpperBodyGeneratorMinimal = (
	traits: GeneratorTrait[],
	tokenId: number,
) => {
	let traitsToFormat = getDeepCopy(traits)
	traitsToFormat = traitsToFormat.filter(
		(trait) => !ignoreCategories.includes(trait.trait_type),
	)
	let generatorTraits: GeneratorTrait[] = traitsToFormat.map((trait) => {
		const formattedTrait: GeneratorTrait = {
			trait_type: trait.trait_type,
			value: trait.value,
		}

		return formattedTrait
	})

	generatorTraits = processHapesForGenerator(generatorTraits, tokenId)
	return generatorTraits
}

const updateHandmadeTraitsForCustomValueUpdates = (
	generatorTraits: GeneratorTrait[],
	tokenId: number,
) => {
	const updates = handmadeToDavenatorCustomValueUpdates[tokenId]

	if (!updates) {
		return generatorTraits
	}

	return generatorTraits.map((gt) => {
		const matchedUpdate = updates.find(
			(update) =>
				gt.trait_type.toLowerCase() ===
					update.traitToFix.category.toLowerCase() &&
				gt.value.toLowerCase() === update.traitToFix.value.toLowerCase(),
		)

		if (matchedUpdate) {
			return {
				trait_type: matchedUpdate.fix.category,
				value: matchedUpdate.fix.value,
			}
		}

		return gt
	})
}

export const processHapesForGenerator = (
	generatorTraits: GeneratorTrait[],
	tokenId: number,
) => {
	// Produce Hair Color, based on Fur
	const hairColor = generatorTraits.find((t) => t.trait_type === "Fur").value
	generatorTraits.push({
		trait_type: "Hair Color",
		value: hairColor,
	})

	const headwear = generatorTraits.find(
		(t) => t.trait_type === "Headwear",
	)?.value
	const haircut = generatorTraits.find((t) => t.trait_type === "Haircut")?.value
	const excludeHaircuts = ["Ponytail", "Braids", "Pigtails"]

	const accessoryIndex = generatorTraits.findIndex(
		(t) => t.trait_type === "Accessory",
	)

	const clothingIndex = generatorTraits.findIndex(
		(t) => t.trait_type === "Clothing",
	)
	const gadgetIndex = generatorTraits.findIndex(
		(t) => t.trait_type === "Gadget",
	)

	const deactivateTheseIfX23Scarf = ["hoodie", "plastic jacket", "fur coat"]
	if (
		generatorTraits[accessoryIndex]?.value === "X23 Scarf (Blue Frost)" &&
		deactivateTheseIfX23Scarf.some((substring) =>
			generatorTraits[clothingIndex]?.value?.toLowerCase()?.includes(substring),
		)
	) {
		generatorTraits.splice(accessoryIndex, 1)
	}

	if (
		generatorTraits[accessoryIndex]?.value === "X23 Scarf (Blue Frost)" &&
		generatorTraits[gadgetIndex]?.trait_type
	) {
		generatorTraits.splice(gadgetIndex, 1)
	}

	// Haircut Rule: Remove Haircut if Headwear is worn, excluding the ones listed above
	// X23 Headphones Exception Override: X23 Headphones (Headwear) overrides rule as it works
	// .. with any haircut.
	if (headwear && haircut) {
		if (
			!excludeHaircuts.includes(haircut) &&
			headwear !== "X23 Headphones (Grey)"
		) {
			const foundIndex = generatorTraits.findIndex(
				(t) => t.trait_type === "Haircut",
			)
			generatorTraits.splice(foundIndex, 1)
		}
	}

	if (femaleHapeTokenIds.includes(tokenId)) {
		generatorTraits.push({
			trait_type: "Eyelashes",
			value: "Eyelashes",
		})
	}

	if (isHandmade(tokenId)) {
		generatorTraits = updateHandmadeTraitsForCustomValueUpdates(
			generatorTraits,
			tokenId,
		)
	}

	return generatorTraits
}

export const getLocalTraitsAfterEquip = (traits: any[], newTrait: any) => {
	let traitsToModify = getDeepCopy(traits)
	const categoryIndex = traitsToModify.findIndex(
		(t) => t.category === newTrait.category,
	)
	if (categoryIndex > -1) {
		traitsToModify[categoryIndex] = newTrait
	} else {
		traitsToModify.push(newTrait)
	}
	return traitsToModify
}

export const getTraitsAfterEquip = (
	traits: MinimalTrait[],
	newTrait: MinimalTrait,
) => {
	let traitsToModify = getDeepCopy(traits)
	// Temporary, should be passed in like this
	const minimalTraits: GeneratorTrait[] = traitsToModify.map((trait) => {
		const formattedTrait: GeneratorTrait = {
			trait_type: trait.category,
			value: trait.value,
		}

		return formattedTrait
	})

	const categoryIndex = minimalTraits.findIndex(
		(t) => t.trait_type === newTrait.category,
	)

	const formattedTrait: GeneratorTrait = {
		trait_type: newTrait.category,
		value: newTrait.value,
	}

	// Found
	if (categoryIndex > -1) {
		minimalTraits[categoryIndex] = formattedTrait
	} else {
		// Not found, meaning you're adding on
		minimalTraits.push(formattedTrait)
	}

	return minimalTraits
}

export const getLocalTraitsAfterRemoval = (
	traits: MinimalTrait[],
	category: string,
) => {
	let traitsToModify = getDeepCopy(traits)
	const categoryIndex = traitsToModify.findIndex((t) => t.category === category)

	if (categoryIndex > -1) {
		traitsToModify.splice(categoryIndex, 1)
	}

	return traitsToModify
}

export const getTraitsAfterRemoval = (
	traits: MinimalTrait[],
	category: string,
) => {
	let traitsToModify = getDeepCopy(traits)
	// Temporary, should be passed in like this
	const generatorTraits: GeneratorTrait[] = traitsToModify.map((trait) => {
		const formattedTrait: GeneratorTrait = {
			trait_type: trait.category,
			value: trait.value,
		}

		return formattedTrait
	})

	const categoryIndex = generatorTraits.findIndex(
		(t) => t.trait_type === category,
	)

	if (categoryIndex > -1) {
		generatorTraits.splice(categoryIndex, 1)
	}

	return generatorTraits
}

export const checkCny2024Traits = (
	traits: MinimalTrait[],
	setModalColorsAsCny2024: () => void,
	resetModalColors: () => void,
) => {
	const hasCnyTrait = traits.some((trait) =>
		trait.value.toLowerCase().includes("cny24"),
	)
	if (hasCnyTrait) {
		// Set CNY2024 colors
		setModalColorsAsCny2024()
	} else {
		// Reset Colors
		resetModalColors()
	}
}

function getDeepCopy(object: Object) {
	return JSON.parse(JSON.stringify(object))
}

export const tempEquipmentPanelThumbnailTraits = [
	{
		category: "Clothing",
		value: "Hape T-Shirt (White)",
		value_core: "Hape T-Shirt",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape T-Shirt (Black)",
		value_core: "Hape T-Shirt",
		possession_qty: 0,
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape T-Shirt (Orange)",
		value_core: "Hape T-Shirt",
		possession_qty: 0,
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape LDN T-Shirt (White)",
		value_core: "Hape LDN T-Shirt",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape LDN T-Shirt (Black)",
		value_core: "Hape LDN T-Shirt",
		possession_qty: 0,
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape LDN T-Shirt (Orange)",
		value_core: "Hape LDN T-Shirt",
		possession_qty: 0,
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Long-Sleeved T-Shirt (White)",
		value_core: "Hape Long-Sleeved T-Shirt",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Epic",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Long-Sleeved T-Shirt (Black)",
		value_core: "Hape Long-Sleeved T-Shirt",
		possession_qty: 0,
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Epic",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Long-Sleeved T-Shirt (Orange)",
		value_core: "Hape Long-Sleeved T-Shirt",
		possession_qty: 0,
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Epic",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Print Hoodie (White)",
		value_core: "Hape Print Hoodie",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Legendary",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Print Hoodie (Black)",
		value_core: "Hape Print Hoodie",
		possession_qty: 0,
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Legendary",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Print Hoodie (Orange)",
		value_core: "Hape Print Hoodie",
		possession_qty: 0,
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Legendary",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Tank Top (White)",
		value_core: "Hape Tank Top",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Tank Top (Black)",
		value_core: "Hape Tank Top",
		possession_qty: 0,
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Hape Tank Top (Orange)",
		value_core: "Hape Tank Top",
		possession_qty: 0,
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Rare",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape 5-Panel Hat (White)",
		value_core: "Hape 5-Panel Hat",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape 5-Panel Hat (Black)",
		value_core: "Hape 5-Panel Hat",
		possession_qty: 0,
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape 5-Panel Hat (Orange)",
		value_core: "Hape 5-Panel Hat",
		possession_qty: 0,
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape Cap (White)",
		value_core: "Hape Cap",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape Cap (Black)",
		value_core: "Hape Cap",
		possession_qty: 0,
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape Cap (Orange)",
		value_core: "Hape Cap",
		possession_qty: 0,
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape Boonie Hat (White)",
		value_core: "Hape Boonie Hat",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape Boonie Hat (Black)",
		value_core: "Hape Boonie Hat",
		possession_qty: 0,
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Headwear",
		value: "Hape Boonie Hat (Orange)",
		value_core: "Hape Boonie Hat",
		possession_qty: 0,
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "Foundation Box",
	},
	{
		category: "Clothing",
		value: "Violet Waves Hoodie (DRESSX)",
		value_core: "Violet Waves Hoodie",
		primary_color: "Violet",
		secondary_color: null,
		rarity_type: "Legendary",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Clothing",
		value: "Orange DX Hoodie (DRESSX)",
		value_core: "Orange DX Hoodie",
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Epic",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Headwear",
		value: "Orange DX Beanie Hat (DRESSX)",
		value_core: "Orange DX Beanie Hat",
		primary_color: "Orange",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Clothing",
		value: "Blue DX Splatter Hoodie (DRESSX)",
		value_core: "Blue DX Splatter Hoodie",
		primary_color: "Blue",
		secondary_color: null,
		rarity_type: "Epic",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Headwear",
		value: "Blue DX Splatter Bucket Hat (DRESSX)",
		value_core: "Blue DX Splatter Bucket Hat",
		primary_color: "Blue",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Clothing",
		value: "Black Chain-Link Hoodie (DRESSX)",
		value_core: "Black Chain-Link Hoodie",
		primary_color: "Black",
		secondary_color: null,
		rarity_type: "Epic",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Clothing",
		value: "Reflective Watercolour Hoodie (DRESSX)",
		value_core: "Reflective Watercolour Hoodie",
		primary_color: "Reflective",
		secondary_color: null,
		rarity_type: "Epic",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Headwear",
		value: "Reflective Watercolour Cap (DRESSX)",
		value_core: "Reflective Watercolour Cap",
		primary_color: "Reflective",
		secondary_color: null,
		rarity_type: "Uncommon",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Headwear",
		value: "Violet Bucket Hat (DRESSX)",
		value_core: "Violet Bucket Hat",
		primary_color: "Violet",
		secondary_color: null,
		rarity_type: "Legendary",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Jewellery",
		value: "Titanium DX Chain with Hape Pendant (DRESSX)",
		value_core: "Titanium DX Chain with Hape Pendant",
		primary_color: "Titanium",
		secondary_color: null,
		rarity_type: "Legendary",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Clothing",
		value: "Reflective Neon T-Shirt (DRESSX)",
		value_core: "Reflective Neon T-Shirt",
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Legendary",
		rarity_score: 0,
		collection: "DressX",
	},
	{
		category: "Clothing",
		value: "X23 Crewneck (Blue Frost)",
		value_core: "X23 Crewneck",
		possession_qty: 0,
		primary_color: "Blue Frost",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "XMAS 2023",
	},
	{
		category: "Accessory",
		value: "X23 Scarf (Blue Frost)",
		value_core: "X23 Scarf",
		possession_qty: 0,
		primary_color: "Blue Frost",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "XMAS 2023",
	},
	{
		category: "Headwear",
		value: "X23 Beanie (Blue Frost)",
		value_core: "X23 Beanie",
		possession_qty: 0,
		primary_color: "Blue Frost",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "XMAS 2023",
	},
	{
		category: "Headwear",
		value: "X23 Headphones (Grey)",
		value_core: "X23 Headphones",
		possession_qty: 0,
		primary_color: "Grey",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "XMAS 2023",
	},
	{
		category: "Headwear",
		value: "X23 Beanie + Headphones (Blue Frost / Grey)",
		value_core: "X23 Beanie + Headphones",
		possession_qty: 0,
		primary_color: "Grey",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "XMAS 2023",
	},
	{
		category: "Special",
		value: "X23 III.O Snowboard (White)",
		value_core: "Snowboard",
		possession_qty: 0,
		primary_color: "White",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "XMAS 2023",
	},
	{
		category: "Clothing",
		value: "CNY24 Traditional Dress (Fortune Red)",
		value_core: "CNY24 Traditional Dress",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Clothing",
		value: "CNY24 Traditional Shirt (Fortune Red)",
		value_core: "CNY24 Traditional Shirt",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Headwear",
		value: "CNY24 Snapback (Fortune Red)",
		value_core: "CNY24 Snapback",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Special",
		value: "CNY24 Lanterns (Fortune Red)",
		value_core: "CNY24 Lanterns",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Jewellery",
		value: "Community Badge",
		value_core: "Community Badge",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Jewellery",
		value: "Holders Badge",
		value_core: "Holders Badge",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Jewellery",
		value: "HAPE Holder Badge (Rare)",
		value_core: "HAPE Holder Badge (Rare)",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Jewellery",
		value: "HAPE Holder Badge (Legendary)",
		value_core: "HAPE Holder Badge (Legendary)",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Jewellery",
		value: "HAPE Holder Badge (Genesis)",
		value_core: "HAPE Holder Badge (Genesis)",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "HAPE NFT NYC Holder Wristband",
		value_core: "HAPE NFT NYC Holder Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "HAPE NFT NYC Attendee Wristband",
		value_core: "HAPE NFT NYC Attendee Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "HAPE NFT NYC VIP Wristband",
		value_core: "HAPE NFT NYC VIP Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "NFT LDN Hape Holder Wristband",
		value_core: "NFT LDN Hape Holder Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "NFT LDN Hape VIP Wristband",
		value_core: "NFT LDN Hape VIP Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "HAPE MET AMS Attendee Wristband",
		value_core: "HAPE MET AMS Attendee Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "HAPE MET AMS Holder Wristband",
		value_core: "HAPE MET AMS Holder Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "HAPE MET AMS VIP Wristband",
		value_core: "HAPE MET AMS VIP Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "Edge Summit 2023 Attendee Wristband",
		value_core: "Edge Summit 2023 Attendee Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "Edge Summit 2023 Hape Holder Wristband",
		value_core: "Edge Summit 2023 Hape Holder Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Wristband",
		value: "Edge Summit 2023 VIP Wristband",
		value_core: "Edge Summit 2023 VIP Wristband",
		possession_qty: 0,
		primary_color: "Red",
		secondary_color: null,
		rarity_type: "Common",
		rarity_score: 0,
		collection: "CNY 2024",
	},
	{
		category: "Clothing",
		value: "JAKxGEM Bomber Jacket",
		value_core: "JAKxGEM Bomber Jacket",
		possession_qty: 0,
		primary_color: "",
		secondary_color: null,
		rarity_type: "",
		rarity_score: 0,
		collection: "",
	},
	{
		category: "Clothing",
		value: "Jeztheartist Hoodie",
		value_core: "Jeztheartist Hoodie",
		possession_qty: 0,
		primary_color: "",
		secondary_color: null,
		rarity_type: "",
		rarity_score: 0,
		collection: "",
	},
	{
		category: "Clothing",
		value: "Jeztheartist T-Shirt",
		value_core: "Jeztheartist T-Shirt",
		possession_qty: 0,
		primary_color: "",
		secondary_color: null,
		rarity_type: "",
		rarity_score: 0,
		collection: "",
	},
	{
		category: "Clothing",
		value: "TFM Crewneck",
		value_core: "TFM Crewneck",
		possession_qty: 0,
		primary_color: "",
		secondary_color: null,
		rarity_type: "",
		rarity_score: 0,
		collection: "",
	},
	{
		category: "Clothing",
		value: "TFM Long Sleeve",
		value_core: "TFM Long Sleeve",
		possession_qty: 0,
		primary_color: "",
		secondary_color: null,
		rarity_type: "",
		rarity_score: 0,
		collection: "",
	},
	{
		category: "Headwear",
		value: "Cobe Snapback",
		value_core: "Cobe Snapback",
		possession_qty: 0,
		primary_color: "",
		secondary_color: null,
		rarity_type: "",
		rarity_score: 0,
		collection: "",
	},
]

// attributes: [
//     {
//         trait_type: "Fur",
//         value: "White"
//     },
//     {
//         trait_type: "Head",
//         value: "HAPE"
//     },
//     {
//         trait_type: "Eyes",
//         value: "Graphite"
//     },
//     {
//         trait_type: "Clothing",
//         value: "Essential Crewneck (Pacific Pink)"
//     },
//     {
//         trait_type: "Jewellery",
//         value: "Icosphere Beads (Titanium / Gold)"
//     },
//     {
//         trait_type: "Haircut",
//         value: "Braids"
//     },
//     {
//         trait_type: "Headwear",
//         value: "Panama (White)"
//     },
//     {
//         trait_type: "Eyewear",
//         value: "Rimless (White)"
//     }
// ]

// HAPE Guard spelling
// Gold Grill

// {
//     trait_type: "Fur",
//     value: "White Zebra"
// },
// {
//     trait_type: "Head",
//     value: "Bullish"
// },
// {
//     trait_type: "Eyes",
//     value: "Graphite"
// },
// {
//     trait_type: "Clothing",
//     value: "VHAPE T-Shirt (Orange)"
// },
// {
//     trait_type: "Jewellery",
//     value: "Goth H Diamond Cuban Link (Gold)"
// },
// {
//     trait_type: "Headwear",
//     value: "HAPE Planet Flat Brim Trucker Hat (White)"
// },
// {
//     trait_type: "Eyewear",
//     value: "Aviator (Gold)"
// },
// {
//     trait_type: "Earrings",
//     value: "Large Stud with Diamonds (Gold)"
// }

// [
//     { trait_type: "Enhanced Background", value: "Bananas" },
//     {
//         trait_type: "Fur",
//         value: "Baby Blue"
//     },
//     {
//         trait_type: "Head",
//         value: "Dopey"
//     },
//     {
//         trait_type: "Eyes",
//         value: "Navy"
//     },
//     {
//         trait_type: "Clothing",
//         value: "HREAM Crewneck (Space Station)"
//     },
//     {
//         trait_type: "Jewellery",
//         value: "Icosphere Beads (Titanium)"
//     },
//     {
//         trait_type: "Headwear",
//         value: "Vintage Cricket Cap (Pistachio)"
//     },
//     { trait_type: "Accessory", value: "Backpack (Pistachio)" },
//     { trait_type: "Earrings", value: "Anarchy Round Clip" },
//     { trait_type: "Duotone", value: "Duotone" }
// ]
