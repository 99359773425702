import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useEffect
} from "react"
import { systemApi } from "../system/helper"

// Define the shape of your context data
interface SystemContextType {
	isLive: boolean
	setIsLive: React.Dispatch<React.SetStateAction<boolean>>
	isMaintenanceModeOn: boolean
	setIsMaintenanceModeOn: React.Dispatch<React.SetStateAction<boolean>>
}

// Create the context with a default value
const SystemContext = createContext<SystemContextType>({
	isLive: true, // default value
	setIsLive: () => {},
	isMaintenanceModeOn: false, // default value
	setIsMaintenanceModeOn: () => {}
})

// Define the props for the provider component
interface SystemProviderProps {
	children: ReactNode
}

// Create a provider component
export const SystemProvider: React.FC<SystemProviderProps> = ({ children }) => {
	const [isLive, setIsLive] = useState<boolean>(true)
	const [isMaintenanceModeOn, setIsMaintenanceModeOn] = useState<boolean>(false)

	useEffect(() => {
		const fetchMaintenanceModeStatus = async () => {
			try {
				const response = await systemApi
					.get(`/maintenance/status/`)
					.then(response => response.data.is_active)
				setIsMaintenanceModeOn(response)
			} catch (error) {
				console.error("Maintenance Mode Error")
			}
		}
		fetchMaintenanceModeStatus()
	}, [])
	return (
		<SystemContext.Provider
			value={{ isLive, setIsLive, isMaintenanceModeOn, setIsMaintenanceModeOn }}
		>
			{children}
		</SystemContext.Provider>
	)
}

// Custom hook to use the SystemContext
export const useSystem = () => useContext(SystemContext)
