import axios from "axios"
import Cookies from "js-cookie"
import { hapeGeneratorUri } from "../dynamic-equipment/helpers"

// Create an axios instance
export const fashionIndexApi = axios.create({
	baseURL: `${process.env.NEXT_PUBLIC_DJANGO_API_BASE_URL}/fashionindex/`, // Your Django REST API base url
	timeout: 50000, // Set a timeout limit
})

export const fashionIndexWeb3AuthApi = axios.create({
	baseURL: `${process.env.NEXT_PUBLIC_DJANGO_API_BASE_URL}/fashionindex/web3auth/`, // Your Django REST API base url
	timeout: 50000, // Set a timeout limit
})

export const davenatorApi = axios.create({
	baseURL: hapeGeneratorUri,
	timeout: 50000,
})
