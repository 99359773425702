import duotoneGradientHapes from "../../zHapeStorage/duotoneGradientHapes"
import gradients from "../../zHapeStorage/gradientsFromGradientHapes"
import { itemNames } from "../enum/constants"

export const getHapeGradient = card => {
	let linearGradient
	let gradientValue = getHapeGradientHelper(card)

	const gradient = gradientValue.horizontal

	linearGradient = `linear-gradient(${"315deg"},${gradient.coord_1}, ${
		gradient.coord_2
	}, ${getLighterShade(gradient.coord_3)})`
	return linearGradient
}

const getLighterShade = colorValue => {
	const rgbaArray = colorValue
		.substring(colorValue.indexOf("(") + 1, colorValue.indexOf(")"))
		.split(",")

	const finalRgba = `rgba(${rgbaArray[0]}, ${rgbaArray[1]}, ${rgbaArray[2]}, .4)`

	return finalRgba
}

export const getHapeGradientHelper = card => {
	const ignoreDuotone = []
	const DUOTONE_CATEGORY = "duotone"
	const FUR_CATEGORY = "Fur"

	const cardTokenId = parseInt(card.token_id)
	// Function to find a trait by category
	const findTraitByCategory = (traits, category) =>
		traits.find(
			trait => trait.category.toLowerCase() === category.toLowerCase()
		)

	// Check if the card has a duotone trait and is not ignored
	const isDuotone =
		findTraitByCategory(card.traits, DUOTONE_CATEGORY) &&
		!ignoreDuotone.includes(cardTokenId)

	let gradientValue

	if (isDuotone) {
		// If duotone, try to find the specific gradient for the card by token_id
		const duotoneGradient = duotoneGradientHapes.find(
			h => h.token_id === cardTokenId
		)?.gradient
		if (duotoneGradient) {
			gradientValue = duotoneGradient
		}
	}

	if (!gradientValue) {
		// If not duotone or no specific duotone gradient found, default to finding a gradient by the Fur trait
		const furTrait = findTraitByCategory(card.traits, FUR_CATEGORY)
		if (furTrait) {
			const furGradient = gradients.find(
				h => h.fur === furTrait.value
			)?.gradient
			gradientValue = furGradient
		}
	}

	return gradientValue
}

// export const getHapeGradientHelper = card => {
// 	const ignoreDuotone = []
// 	const isDuotone = card.traits.find(
// 		t => t.category.toLowerCase() === "duotone"
// 	)
// 	let gradientValue

// 	if (isDuotone && !ignoreDuotone.includes(card.token_id)) {
// 		gradientValue = duotoneGradientHapes.find(
// 			h => h.token_id === card.token_id
// 		)?.gradient
// 	} else {
// 		gradientValue = gradients.find(
// 			h => h.fur === card.traits.find(t => t.category === "Fur").value
// 		).gradient
// 	}

// 	return gradientValue
// }

export const getHapeImageUrl = (hape, imageDimensions) => {
	const itemType = hape.equipment.badge.itemType

	if (itemType) {
		const awsUrl = itemNames.find(
			i => i.blockchainName === itemType
		)?.baseAwsUrl

		const path = `/${imageDimensions}x${imageDimensions}/${
			hape.token_id
		}.${"png"}`
		const url = `${awsUrl}${path}`
		return url
	} else {
		return `https://dpqmbay1docqc.cloudfront.net/fit-in/${imageDimensions}x${imageDimensions}/${hape.token_id}.png`
	}
}

export const getOriginalHapeImageUrl = (tokenId, imageDimensions) => {
	return `https://d2txl75rmr4hou.cloudfront.net/fit-in/${imageDimensions}x${imageDimensions}/${tokenId}.png`
}
