const gradients = [
	{
		fur: "Baby Blue",
		gradient: {
			horizontal: {
				coord_1: "rgba(109,203,214,255)",
				coord_2: "rgba(129,209,219,255)",
				coord_3: "rgba(156,218,226,255)"
			},
			vertical: {
				coord_1: "rgba(174,226,231,255)",
				coord_2: "rgba(156,218,226,255)"
			}
		}
	},
	{
		fur: "Baby Blue Leopard",
		gradient: {
			horizontal: {
				coord_1: "rgba(109,203,214,255)",
				coord_2: "rgba(129,209,219,255)",
				coord_3: "rgba(156,218,226,255)"
			},
			vertical: {
				coord_1: "rgba(174,226,231,255)",
				coord_2: "rgba(156,218,226,255)"
			}
		}
	},
	{
		fur: "Blue",
		gradient: {
			horizontal: {
				coord_1: "rgba(1,51,136,255)",
				coord_2: "rgba(0,59,148,255)",
				coord_3: "rgba(0,73,169,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,73,169,255)"
			}
		}
	},
	{
		fur: "Brown",
		gradient: {
			horizontal: {
				coord_1: "rgba(53,22,1,255)",
				coord_2: "rgba(61,26,1,255)",
				coord_3: "rgba(73,35,3,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(73,35,3,255)"
			}
		}
	},
	{
		fur: "Bubble Gum",
		gradient: {
			horizontal: {
				coord_1: "rgba(219,35,132,255)",
				coord_2: "rgba(231,49,142,255)",
				coord_3: "rgba(243,71,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,176,255)",
				coord_2: "rgba(243,71,161,255)"
			}
		}
	},
	{
		fur: "Bubble Gum Cheetah",
		gradient: {
			horizontal: {
				coord_1: "rgba(219,35,132,255)",
				coord_2: "rgba(231,49,142,255)",
				coord_3: "rgba(243,71,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,176,255)",
				coord_2: "rgba(243,71,161,255)"
			}
		}
	},
	{
		fur: "Champagne",
		gradient: {
			horizontal: {
				coord_1: "rgba(212,202,182,255)",
				coord_2: "rgba(218,207,192,255)",
				coord_3: "rgba(225,215,202,255)"
			},
			vertical: {
				coord_1: "rgba(230,225,213,255)",
				coord_2: "rgba(225,215,202,255)"
			}
		}
	},
	{
		fur: "Cheetah",
		gradient: {
			horizontal: {
				coord_1: "rgba(53,22,1,255)",
				coord_2: "rgba(61,26,1,255)",
				coord_3: "rgba(73,35,3,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(73,35,3,255)"
			}
		}
	},
	{
		fur: "Computer Green",
		gradient: {
			horizontal: {
				coord_1: "rgba(20,184,33,255)",
				coord_2: "rgba(49,194,45,255)",
				coord_3: "rgba(88,207,64,255)"
			},
			vertical: {
				coord_1: "rgba(115,217,80,255)",
				coord_2: "rgba(88,207,64,255)"
			}
		}
	},
	{
		fur: "Cream",
		gradient: {
			horizontal: {
				coord_1: "rgba(207,194,173,255)",
				coord_2: "rgba(213,202,186,255)",
				coord_3: "rgba(223,214,201,255)"
			},
			vertical: {
				coord_1: "rgba(228,221,207,255)",
				coord_2: "rgba(223,214,201,255)"
			}
		}
	},
	{
		fur: "Croc Green",
		gradient: {
			horizontal: {
				coord_1: "rgba(20,184,33,255)",
				coord_2: "rgba(49,194,45,255)",
				coord_3: "rgba(88,207,64,255)"
			},
			vertical: {
				coord_1: "rgba(117,216,80,255)",
				coord_2: "rgba(88,207,64,255)"
			}
		}
	},
	{
		fur: "Desert Storm",
		gradient: {
			horizontal: {
				coord_1: "rgba(212,202,182,255)",
				coord_2: "rgba(218,207,192,255)",
				coord_3: "rgba(225,215,202,255)"
			},
			vertical: {
				coord_1: "rgba(230,225,213,255)",
				coord_2: "rgba(225,215,202,255)"
			}
		}
	},
	{
		fur: "Furless Gold",
		gradient: {
			horizontal: {
				coord_1: "rgba(203,203,203,255)",
				coord_2: "rgba(208,208,208,255)",
				coord_3: "rgba(217,217,217,255)"
			},
			vertical: {
				coord_1: "rgba(225,225,225,255)",
				coord_2: "rgba(217,217,217,255)"
			}
		}
	},
	{
		fur: "Geometric Urban",
		gradient: {
			horizontal: {
				coord_1: "rgba(27,27,27,255)",
				coord_2: "rgba(31,31,31,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		fur: "Graphite",
		gradient: {
			horizontal: {
				coord_1: "rgba(27,27,27,255)",
				coord_2: "rgba(31,31,31,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		fur: "Illuminating",
		gradient: {
			horizontal: {
				coord_1: "rgba(213,201,91,255)",
				coord_2: "rgba(217,207,103,255)",
				coord_3: "rgba(226,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(229,225,142,255)",
				coord_2: "rgba(226,216,124,255)"
			}
		}
	},
	{
		fur: "Illuminating Spackle",
		gradient: {
			horizontal: {
				coord_1: "rgba(213,201,91,255)",
				coord_2: "rgba(217,207,103,255)",
				coord_3: "rgba(226,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(229,225,142,255)",
				coord_2: "rgba(226,216,124,255)"
			}
		}
	},
	{
		fur: "Lavender",
		gradient: {
			horizontal: {
				coord_1: "rgba(53,44,154,255)",
				coord_2: "rgba(65,53,169,255)",
				coord_3: "rgba(86,69,188,255)"
			},
			vertical: {
				coord_1: "rgba(98,78,201,255)",
				coord_2: "rgba(86,69,188,255)"
			}
		}
	},
	{
		fur: "Lime",
		gradient: {
			horizontal: {
				coord_1: "rgba(197,214,54,255)",
				coord_2: "rgba(204,220,69,255)",
				coord_3: "rgba(215,226,90,255)"
			},
			vertical: {
				coord_1: "rgba(223,232,108,255)",
				coord_2: "rgba(215,226,90,255)"
			}
		}
	},
	{
		fur: "Lime Stripes",
		gradient: {
			horizontal: {
				coord_1: "rgba(197,214,54,255)",
				coord_2: "rgba(204,220,69,255)",
				coord_3: "rgba(215,226,90,255)"
			},
			vertical: {
				coord_1: "rgba(223,232,108,255)",
				coord_2: "rgba(215,226,90,255)"
			}
		}
	},
	{
		fur: "Navy",
		gradient: {
			horizontal: {
				coord_1: "rgba(27,32,58,255)",
				coord_2: "rgba(32,39,66,255)",
				coord_3: "rgba(41,47,79,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(41,47,79,255)"
			}
		}
	},
	{
		fur: "Navy Seals",
		gradient: {
			horizontal: {
				coord_1: "rgba(27,32,58,255)",
				coord_2: "rgba(32,39,66,255)",
				coord_3: "rgba(41,47,79,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(41,47,79,255)"
			}
		}
	},
	{
		fur: "Oblivion",
		gradient: {
			horizontal: {
				coord_1: "rgba(203,203,203,255)",
				coord_2: "rgba(208,208,208,255)",
				coord_3: "rgba(217,217,217,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(217,217,217,255)"
			}
		}
	},
	{
		fur: "Obsidian",
		gradient: {
			horizontal: {
				coord_1: "rgba(24,24,24,255)",
				coord_2: "rgba(37,37,37,255)",
				coord_3: "rgba(44,44,44,255)"
			},
			vertical: {
				coord_1: "rgba(44,44,44,255)",
				coord_2: "rgba(44,44,44,255)"
			}
		}
	},
	{
		fur: "Orange",
		gradient: {
			horizontal: {
				coord_1: "rgba(218,88,5,255)",
				coord_2: "rgba(226,100,8,255)",
				coord_3: "rgba(237,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(246,139,27,255)",
				coord_2: "rgba(237,120,18,255)"
			}
		}
	},
	{
		fur: "Pacific Pink",
		gradient: {
			horizontal: {
				coord_1: "rgba(213,175,185,255)",
				coord_2: "rgba(218,185,194,255)",
				coord_3: "rgba(225,199,205,255)"
			},
			vertical: {
				coord_1: "rgba(231,210,215,255)",
				coord_2: "rgba(225,199,205,255)"
			}
		}
	},
	{
		fur: "Peri Tone",
		gradient: {
			horizontal: {
				coord_1: "rgba(78,78,160,255)",
				coord_2: "rgba(88,88,172,255)",
				coord_3: "rgba(107,107,191,255)"
			},
			vertical: {
				coord_1: "rgba(126,124,207,255)",
				coord_2: "rgba(107,107,191,255)"
			}
		}
	},
	{
		fur: "Peri Waves",
		gradient: {
			horizontal: {
				coord_1: "rgba(78,78,160,255)",
				coord_2: "rgba(88,88,172,255)",
				coord_3: "rgba(107,107,191,255)"
			},
			vertical: {
				coord_1: "rgba(126,124,207,255)",
				coord_2: "rgba(107,107,191,255)"
			}
		}
	},
	{
		fur: "Pinky Dunes",
		gradient: {
			horizontal: {
				coord_1: "rgba(213,175,185,255)",
				coord_2: "rgba(218,185,194,255)",
				coord_3: "rgba(225,199,205,255)"
			},
			vertical: {
				coord_1: "rgba(231,210,215,255)",
				coord_2: "rgba(225,199,205,255)"
			}
		}
	},
	{
		fur: "Pistachio",
		gradient: {
			horizontal: {
				coord_1: "rgba(157,213,146,255)",
				coord_2: "rgba(170,218,158,255)",
				coord_3: "rgba(188,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,231,190,255)",
				coord_2: "rgba(188,225,176,255)"
			}
		}
	},
	{
		fur: "Pistachio Leaves",
		gradient: {
			horizontal: {
				coord_1: "rgba(157,213,146,255)",
				coord_2: "rgba(170,218,158,255)",
				coord_3: "rgba(188,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,231,190,255)",
				coord_2: "rgba(188,225,176,255)"
			}
		}
	},
	{
		fur: "Red",
		gradient: {
			horizontal: {
				coord_1: "rgba(151,0,1,255)",
				coord_2: "rgba(164,0,3,255)",
				coord_3: "rgba(185,0,3,255)"
			},
			vertical: {
				coord_1: "rgba(204,0,7,255)",
				coord_2: "rgba(185,0,3,255)"
			}
		}
	},
	{
		fur: "Red Camo",
		gradient: {
			horizontal: {
				coord_1: "rgba(151,0,1,255)",
				coord_2: "rgba(164,0,3,255)",
				coord_3: "rgba(185,0,3,255)"
			},
			vertical: {
				coord_1: "rgba(204,0,7,255)",
				coord_2: "rgba(185,0,3,255)"
			}
		}
	},
	{
		fur: "Tiger Style",
		gradient: {
			horizontal: {
				coord_1: "rgba(218,88,5,255)",
				coord_2: "rgba(226,100,8,255)",
				coord_3: "rgba(237,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(246,139,27,255)",
				coord_2: "rgba(237,120,18,255)"
			}
		}
	},
	{
		fur: "Ultimate Elephant",
		gradient: {
			horizontal: {
				coord_1: "rgba(132,134,136,255)",
				coord_2: "rgba(144,146,148,255)",
				coord_3: "rgba(163,165,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,180,181,255)",
				coord_2: "rgba(163,165,166,255)"
			}
		}
	},
	{
		fur: "Ultimate Grey",
		gradient: {
			horizontal: {
				coord_1: "rgba(132,134,136,255)",
				coord_2: "rgba(144,146,148,255)",
				coord_3: "rgba(163,165,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,180,181,255)",
				coord_2: "rgba(163,165,166,255)"
			}
		}
	},
	{
		fur: "Urban Camo",
		gradient: {
			horizontal: {
				coord_1: "rgba(1,51,136,255)",
				coord_2: "rgba(0,59,148,255)",
				coord_3: "rgba(0,73,169,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,73,169,255)"
			}
		}
	},
	{
		fur: "Velvet Violet",
		gradient: {
			horizontal: {
				coord_1: "rgba(73,10,134,255)",
				coord_2: "rgba(85,13,147,255)",
				coord_3: "rgba(103,20,167,255)"
			},
			vertical: {
				coord_1: "rgba(121,28,182,255)",
				coord_2: "rgba(103,20,167,255)"
			}
		}
	},
	{
		fur: "Violent Violet",
		gradient: {
			horizontal: {
				coord_1: "rgba(25,0,62,255)",
				coord_2: "rgba(85,13,147,255)",
				coord_3: "rgba(103,20,167,255)"
			},
			vertical: {
				coord_1: "rgba(121,28,182,255)",
				coord_2: "rgba(103,20,167,255)"
			}
		}
	},
	{
		fur: "White",
		gradient: {
			horizontal: {
				coord_1: "rgba(200,200,200,255)",
				coord_2: "rgba(215,215,215,255)",
				coord_3: "rgba(221,221,221,255)"
			},
			vertical: {
				coord_1: "rgba(221,221,221,255)",
				coord_2: "rgba(221,221,221,255)"
			}
		}
	},
	{
		fur: "White Zebra",
		gradient: {
			horizontal: {
				coord_1: "rgba(203,203,203,255)",
				coord_2: "rgba(208,208,208,255)",
				coord_3: "rgba(217,217,217,255)"
			},
			vertical: {
				coord_1: "rgba(225,225,225,255)",
				coord_2: "rgba(217,217,217,255)"
			}
		}
	},
	{
		fur: "Yellow",
		gradient: {
			horizontal: {
				coord_1: "rgba(219,158,12,255)",
				coord_2: "rgba(226,170,25,255)",
				coord_3: "rgba(232,187,43,255)"
			},
			vertical: {
				coord_1: "rgba(238,199,57,255)",
				coord_2: "rgba(232,187,43,255)"
			}
		}
	},
	{
		fur: "Yellow Dust",
		gradient: {
			horizontal: {
				coord_1: "rgba(219,158,12,255)",
				coord_2: "rgba(226,170,25,255)",
				coord_3: "rgba(232,187,43,255)"
			},
			vertical: {
				coord_1: "rgba(238,199,57,255)",
				coord_2: "rgba(232,187,43,255)"
			}
		}
	}
]

export default gradients
