import { useState } from "react"

function useDarkMode() {
	const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false)

	const toggleDarkMode = () => {
		setIsDarkModeEnabled(!isDarkModeEnabled)
	}

	return {
		isDarkModeEnabled,
		toggleDarkMode
	}
}

export default useDarkMode
