import React, { createContext } from "react"
import useModal from "../hooks/useModal"

const ModalContext = createContext()

function ModalProvider({ children }) {
	return (
		<ModalContext.Provider value={useModal()}>
			{children}
		</ModalContext.Provider>
	)
}

export { ModalContext, ModalProvider }
