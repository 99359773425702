export const CollectionMapping = {
	"all-black-everything": "All Black Everything",
	sporty: "Sporty",
	digi: "Digi",
	"roar-tigerstyle": "Roar Tigerstyle",
	"foundation-box": "Foundation Box",
}

export const ClothingMapping = [
	{
		category: "Clothing",
		value: "Essential T-Shirt (Graphite)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_TSHIRT_ESSENTIAL_GRAPHITE.png",
	},
	{
		category: "Clothing",
		value: "HREAM Crewneck (Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_CREWNECK_HREAM_BLACK.png",
	},
	{
		category: "Clothing",
		value: "Fur Coat (Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_FUR_COAT_BLACK.png",
	},
	{
		category: "Clothing",
		value: "Military Jacket (Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_MILITARY_JACKET_BLACK.png",
	},
	{
		category: "Clothing",
		value: "Goth Crewneck (Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_CREWNECK_GOTH_BLACK.png",
	},
	{
		category: "Clothing",
		value: "HB Hoodie (Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_HOODIE_HB_BLACK.png",
	},
	{
		category: "Clothing",
		value: "T-Shirt with Bulletproof Vest (Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_TSHIRT_WITH_BULLETPROOF_VEST_BLACK.png",
	},
	{
		category: "Clothing",
		value: "Bomber Jacket with Hoodie (Black on Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_BOMBER_JACKET_WITH_HOODIE_BLACK_ON_BLACK.png",
	},
	{
		category: "Clothing",
		value: "Essential Hoodie (Graphite)",
		mappedCollection: "all-black-everything",
		mappedValue: "CLOTHING_MAIN_HOODIE_ESSENTIAL_GRAPHITE.png",
	},
	{
		category: "Eyewear",
		value: "Ski Goggles (Black on Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "EYEWARE_SKI-GOOGLES_BLACK-ON-BLACK_SIDE.png",
	},
	{
		category: "Eyewear",
		value: "Beachwalker (Graphite)",
		mappedCollection: "all-black-everything",
		mappedValue: "EYEWEAR_BEACHWALKER_GRAPHITE_SIDE.png",
	},
	{
		category: "Eyewear",
		value: "Square Frames (Black / Gold)",
		mappedCollection: "all-black-everything",
		mappedValue: "EYEWARE_SQUARE-FRAMES_BLACK-GOLD_SIDE.png",
	},
	{
		category: "Headwear",
		value: "Long Durag (Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "HEADWARE_DURAG_BLACK_SIDE.png",
	},
	{
		category: "Headwear",
		value: "H bandana (Black)",
		mappedCollection: "all-black-everything",
		mappedValue: "HEADWARE_BANDANA-FRONT_BLACK_SIDE.png",
	},
	{
		category: "Clothing",
		value: "Fur Coat (White)",
		mappedCollection: "digi",
		mappedValue: "CLOTHING_MAIN_FUR_COAT_WHITE.png",
	},
	{
		category: "Headwear",
		value: "H Snapback (White)",
		mappedCollection: "digi",
		mappedValue: "HEADWEAR_SNAPBACK_BACK_WHITE_SIDE.png",
	},
	{
		category: "Earrings",
		value: "Cross (Platinum)",
		mappedCollection: "digi",
		mappedValue: "EARRINGS_CROSS_PLATINUM_TWO.png",
	},
	{
		category: "Jewellery",
		value: "Diamond Cuban Link (Platinum)",
		mappedCollection: "digi",
		mappedValue: "15-JEWELLERY_CUBAN-LINK-WITH-DIAMONDS_PLATINUM_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Essential T-Shirt (White)",
		mappedCollection: "digi",
		mappedValue: "CLOTHING_MAIN_TSHIRT_ESSENTIAL_WHITE.png",
	},
	{
		category: "Headwear",
		value: "99 Bandana (White)",
		mappedCollection: "sporty",
		mappedValue: "HEADWARE_BANDANA-FRONT_99_WHITE_SIDE.png",
	},
	{
		category: "Clothing",
		value: "American Football Jersey (Orange)",
		mappedCollection: "sporty",
		mappedValue: "CLOTHING_MAIN_AMERICAN_FOOTBALL_JERSEY_ORANGE.png",
	},
	{
		category: "Clothing",
		value: "American Football Jersey (White)",
		mappedCollection: "sporty",
		mappedValue: "CLOTHING_MAIN_AMERICAN_FOOTBALL_JERSEY_WHITE.png",
	},
	{
		category: "Clothing",
		value: "American Football Jersey (Black)",
		mappedCollection: "sporty",
		mappedValue: "CLOTHING_MAIN_AMERICAN_FOOTBALL_JERSEY_BLACK.png",
	},
	{
		category: "Headwear",
		value: "Baseball Hat (White)",
		mappedCollection: "sporty",
		mappedValue: "HEADWEAR_BASEBALL-HATS_WHITE_SIDE.png",
	},
	{
		category: "Headwear",
		value: "Baseball Hat (Black)",
		mappedCollection: "sporty",
		mappedValue: "HEADWEAR_BASEBALL-HATS_BLACK_SIDE.png",
	},
	{
		category: "Headwear",
		value: "99 Bandana (Black)",
		mappedCollection: "sporty",
		mappedValue: "HEADWARE_BANDANA-FRONT_99_BLACK_SIDE.png",
	},
	{
		category: "Eyewear",
		value: "Racing Glasses (White / Gold)",
		mappedCollection: "sporty",
		mappedValue: "EYEWARE_RACING-GLASSES_WHITE-GOLD_SIDE.png",
	},
	{
		category: "Eyewear",
		value: "Racing Glasses (Black / Gold)",
		mappedCollection: "sporty",
		mappedValue: "EYEWARE_RACING-GLASSES_BLACK-GOLD_SIDE.png",
	},
	{
		category: "Clothing",
		value: "Baseball Jersey (Black / Orange)",
		mappedCollection: "sporty",
		mappedValue: "CLOTHING_MAIN_BASEBALL_JERSEY_BLACK_ORANGE.png",
	},
	{
		category: "Clothing",
		value: "Baseball Jersey (White)",
		mappedCollection: "sporty",
		mappedValue: "CLOTHING_MAIN_BASEBALL_JERSEY_WHITE.png",
	},
	{
		category: "Clothing",
		value: "Baseball Jersey (Orange)",
		mappedCollection: "sporty",
		mappedValue: "CLOTHING_MAIN_BASEBALL_JERSEY_ORANGE.png",
	},
	{
		category: "Clothing",
		value: "Crewneck with Puff Vest (Tiger Style)",
		mappedCollection: "roar-tigerstyle",
		mappedValue: "CLOTHING_MAIN_CREWNECK_WITH_PUFF_VEST_TIGER_STYLE.png",
	},
	{
		category: "Clothing",
		value: "Goth Crewneck (Tiger Style)",
		mappedCollection: "roar-tigerstyle",
		mappedValue: "CLOTHING_MAIN_CREWNECK_GOTH_TIGER_STYLE.png",
	},
	{
		category: "Clothing",
		value: "Essential T-Shirt (Tiger Style)",
		mappedCollection: "roar-tigerstyle",
		mappedValue: "CLOTHING_MAIN_TSHIRT_ESSENTIAL_TIGER_STYLE.png",
	},
	{
		category: "Headwear",
		value: "5 Panel (Tiger Skin)",
		mappedCollection: "roar-tigerstyle",
		mappedValue: "HEADWEAR_FIVE-PANELS_TIGER-SKIN_SIDE.png",
	},
	{
		category: "Headwear",
		value: "HAPE Flat Brim Trucker Hat (Tiger Skin)",
		mappedCollection: "roar-tigerstyle",
		mappedValue: "HEADWEAR_FLAT-TRIM-TRUCKER-HAT_HAPE_TIGER-SKIN_SIDE.png",
	},
	{
		category: "Headwear",
		value: "HB Snapback (Tiger Skin)",
		mappedCollection: "roar-tigerstyle",
		mappedValue: "HEADWEAR_SNAPBACK_HB-TEAM_TIGER_SIDE.png",
	},
	{
		category: "Clothing",
		value: "Hape T-Shirt (White)",
		mappedCollection: "foundation-box",
		mappedValue: "T-SHIRT_WHITE_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape T-Shirt (Black)",
		mappedCollection: "foundation-box",
		mappedValue: "T-SHIRT_BLACK_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape T-Shirt (Orange)",
		mappedCollection: "foundation-box",
		mappedValue: "T-SHIRT_ORANGE_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape LDN T-Shirt (White)",
		mappedCollection: "foundation-box",
		mappedValue: "HAPE_LDN_T-SHIRT_WHITE_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape LDN T-Shirt (Black)",
		mappedCollection: "foundation-box",
		mappedValue: "HAPE_LDN_T-SHIRT_BLACK_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape LDN T-Shirt (Orange)",
		mappedCollection: "foundation-box",
		mappedValue: "HAPE_LDN_T-SHIRT_ORANGE_WHITE.png",
	},
	{
		category: "Clothing",
		value: "Hape Long-Sleeved T-Shirt (White)",
		mappedCollection: "foundation-box",
		mappedValue: "LONG_SLEEVED_T-SHIRT_WHITE_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape Long-Sleeved T-Shirt (Black)",
		mappedCollection: "foundation-box",
		mappedValue: "LONG_SLEEVED_T-SHIRT_BLACK_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape Long-Sleeved T-Shirt (Orange)",
		mappedCollection: "foundation-box",
		mappedValue: "LONG_SLEEVED_T-SHIRT_ORANGE_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape Print Hoodie (White)",
		mappedCollection: "foundation-box",
		mappedValue: "PRINT_HOODIE_WHITE.png",
	},
	{
		category: "Clothing",
		value: "Hape Print Hoodie (Black)",
		mappedCollection: "foundation-box",
		mappedValue: "PRINT_HOODIE_BLACK.png",
	},
	{
		category: "Clothing",
		value: "Hape Print Hoodie (Orange)",
		mappedCollection: "foundation-box",
		mappedValue: "PRINT_HOODIE_ORANGE.png",
	},
	{
		category: "Clothing",
		value: "Hape Tank Top (White)",
		mappedCollection: "foundation-box",
		mappedValue: "TANK_TOP_WHITE_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape Tank Top (Black)",
		mappedCollection: "foundation-box",
		mappedValue: "TANK_TOP_BLACK_FRONT.png",
	},
	{
		category: "Clothing",
		value: "Hape Tank Top (Orange)",
		mappedCollection: "foundation-box",
		mappedValue: "TANK_TOP_ORANGE_WHITE.png",
	},
	{
		category: "Headwear",
		value: "Hape 5-Panel Hat (White)",
		mappedCollection: "foundation-box",
		mappedValue: "FIVE_PANELS_WHITE_FRONT.png",
	},
	{
		category: "Headwear",
		value: "Hape 5-Panel Hat (Black)",
		mappedCollection: "foundation-box",
		mappedValue: "FIVE_PANELS_BLACK_FRONT.png",
	},
	{
		category: "Headwear",
		value: "Hape 5-Panel Hat (Orange)",
		mappedCollection: "foundation-box",
		mappedValue: "FIVE_PANELS_ORANGE_FRONT.png",
	},
	{
		category: "Headwear",
		value: "Hape Cap (White)",
		mappedCollection: "foundation-box",
		mappedValue: "BASEBALL_CAP_WHITE_FRONT.png",
	},
	{
		category: "Headwear",
		value: "Hape Cap (Black)",
		mappedCollection: "foundation-box",
		mappedValue: "BASEBALL_CAP_BLACK_FRONT.png",
	},
	{
		category: "Headwear",
		value: "Hape Cap (Orange)",
		mappedCollection: "foundation-box",
		mappedValue: "BASEBALL_CAP_ORANGE_FRONT.png",
	},
	{
		category: "Headwear",
		value: "Hape Boonie Hat (White)",
		mappedCollection: "foundation-box",
		mappedValue: "WHITE0004.png",
	},
	{
		category: "Headwear",
		value: "Hape Boonie Hat (Black)",
		mappedCollection: "foundation-box",
		mappedValue: "BLACK0004.png",
	},
	{
		category: "Headwear",
		value: "Hape Boonie Hat (Orange)",
		mappedCollection: "foundation-box",
		mappedValue: "ORANGE0004.png",
	},
	{
		category: "Clothing",
		value: "Violet Waves Hoodie (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "VIOLET_WAVES_HOODIE.png",
	},
	{
		category: "Clothing",
		value: "Orange DX Hoodie (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "ORANGE_DX_HOODIE.png",
	},
	{
		category: "Headwear",
		value: "Orange DX Beanie Hat (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "ORANGE_DX_BEANIE_HAT.png",
	},
	{
		category: "Clothing",
		value: "Blue DX Splatter Hoodie (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "BLUE_DX_SPLATTER_HOODIE.png",
	},
	{
		category: "Headwear",
		value: "Blue DX Splatter Bucket Hat (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "BLUE_DX_SPLATTER_BUCKET_HAT.png",
	},
	{
		category: "Clothing",
		value: "Black Chain-Link Hoodie (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "BLACK_CHAIN-LINK_HOODIE.png",
	},
	{
		category: "Clothing",
		value: "Reflective Watercolour Hoodie (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "REFLECTIVE_WATERCOLOURS_HOODIE.png",
	},
	{
		category: "Headwear",
		value: "Reflective Watercolour Cap (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "REFLECTIVE_WATERCOLOUR_CAP.png",
	},
	{
		category: "Headwear",
		value: "Violet Bucket Hat (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "VIOLET_DRESSX_BUCKET_HAT.png",
	},
	{
		category: "Jewellery",
		value: "Titanium DX Chain with Hape Pendant (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "DRESSX_CHAIN_WITH_HAPE_PENDANT.png",
	},
	{
		category: "Clothing",
		value: "Reflective Neon T-Shirt (DRESSX)",
		mappedCollection: "dressx",
		mappedValue: "REFLECTIVE_NEON_T-SHIRT.png",
	},
	{
		category: "Clothing",
		value: "X23 Crewneck (Blue Frost)",
		mappedCollection: "x23",
		mappedValue: "CLOTHING_X23_CREWNECK_BLUE_FROST.png",
	},
	{
		category: "Accessory",
		value: "X23 Scarf (Blue Frost)",
		mappedCollection: "x23",
		mappedValue: "ACCESSORY_X23_SCARF_BLUE_FROST.png",
	},
	{
		category: "Headwear",
		value: "X23 Beanie (Blue Frost)",
		mappedCollection: "x23",
		mappedValue: "X23_BEANIE_BLUE_FROST.png",
	},
	{
		category: "Headwear",
		value: "X23 Headphones (Grey)",
		mappedCollection: "x23",
		mappedValue: "X23_HEADPHONES_GREY.png",
	},
	{
		category: "Special",
		value: "X23 III.O Snowboard (White)",
		mappedCollection: "x23",
		mappedValue: "III.O_SNOWBOARD_WHITE.png",
	},
	{
		category: "Headwear",
		value: "X23 Beanie + Headphones (Blue Frost / Grey)",
		mappedCollection: "x23",
		mappedValue: "X23_Beanie_Headphones_Blue_Frost__Grey.png",
	},
	{
		category: "Headwear",
		value: "CNY24 Snapback (Fortune Red)",
		mappedCollection: "cny24",
		mappedValue: "CNY24_SNAPBACK_THUMBNAIL.png",
	},
	{
		category: "Clothing",
		value: "CNY24 Traditional Dress (Fortune Red)",
		mappedCollection: "cny24",
		mappedValue: "CNY24_DRESS_THUMBNAIL_v3.png",
	},
	{
		category: "Clothing",
		value: "CNY24 Traditional Shirt (Fortune Red)",
		mappedCollection: "cny24",
		mappedValue: "CNY24_SHIRT_THUMBNAIL.png",
	},
	{
		category: "Special",
		value: "CNY24 Lanterns (Fortune Red)",
		mappedCollection: "cny24",
		mappedValue: "CNY24_LANTERNS_THUMBNAIL.png",
	},
	{
		category: "Jewellery",
		value: "Community Badge",
		mappedCollection: "badge",
		mappedValue: "community_badge.png",
	},
	{
		category: "Jewellery",
		value: "Holders Badge",
		mappedCollection: "Badge",
		mappedValue: "holders_badge.png",
	},
	{
		category: "Jewellery",
		value: "HAPE Holder Badge (Rare)",
		mappedCollection: "badge",
		mappedValue: "rare_badge.png",
	},
	{
		category: "Jewellery",
		value: "HAPE Holder Badge (Legendary)",
		mappedCollection: "badge",
		mappedValue: "legendary_badge.png",
	},
	{
		category: "Jewellery",
		value: "HAPE Holder Badge (Genesis)",
		mappedCollection: "badge",
		mappedValue: "genesis_badge.png",
	},
	{
		category: "Wristband",
		value: "HAPE NFT NYC Holder Wristband",
		mappedCollection: "wristband",
		mappedValue: "new_nft_nyc_holder_wristband.png",
	},
	{
		category: "Wristband",
		value: "HAPE NFT NYC Attendee Wristband",
		mappedCollection: "wristband",
		mappedValue: "new_nft_nyc_attendee_wristband.png",
	},
	{
		category: "Wristband",
		value: "HAPE NFT NYC VIP Wristband",
		mappedCollection: "wristband",
		mappedValue: "new_nft_nyc_vip_wristband.png",
	},
	{
		category: "Wristband",
		value: "NFT LDN Hape Holder Wristband",
		mappedCollection: "wristband",
		mappedValue: "nft_ldn_holder_wristband.png",
	},
	{
		category: "Wristband",
		value: "NFT LDN Hape VIP Wristband",
		mappedCollection: "wristband",
		mappedValue: "nft_ldn_vip_wristband.png",
	},
	{
		category: "Wristband",
		value: "HAPE MET AMS Attendee Wristband",
		mappedCollection: "wristband",
		mappedValue: "new_met_ams_attendee_wristband.png",
	},
	{
		category: "Wristband",
		value: "HAPE MET AMS Holder Wristband",
		mappedCollection: "wristband",
		mappedValue: "new_met_ams_holder_wristband.png",
	},
	{
		category: "Wristband",
		value: "HAPE MET AMS VIP Wristband",
		mappedCollection: "wristband",
		mappedValue: "new_met_ams_vip_wristband.png",
	},
	{
		category: "Wristband",
		value: "Edge Summit 2023 Attendee Wristband",
		mappedCollection: "wristband",
		mappedValue: "edge_hk_attendee_wristband.png",
	},
	{
		category: "Wristband",
		value: "Edge Summit 2023 Hape Holder Wristband",
		mappedCollection: "wristband",
		mappedValue: "edge_hk_holder_wristband.png",
	},
	{
		category: "Wristband",
		value: "Edge Summit 2023 VIP Wristband",
		mappedCollection: "wristband",
		mappedValue: "edge_hk_vip_wristband.png",
	},
	{
		category: "Clothing",
		value: "JAKxGEM BMBR",
		mappedCollection: "community",
		mappedValue: "JAKxGEM_BMBR.png",
	},
	{
		category: "Clothing",
		value: "Jeztheartist Hoodie",
		mappedCollection: "community",
		mappedValue: "Jeztheartist_Hoodie.png",
	},
	{
		category: "Clothing",
		value: "Jeztheartist T-Shirt",
		mappedCollection: "community",
		mappedValue: "JeztheartisT_T-shirt.png",
	},
	{
		category: "Clothing",
		value: "TFM Crewneck",
		mappedCollection: "community",
		mappedValue: "TFM_Crewneck.png",
	},
	{
		category: "Clothing",
		value: "TFM Long-Sleeve",
		mappedCollection: "community",
		mappedValue: "TFM_Long-sleeve.png",
	},
	{
		category: "Headwear",
		value: "Cobe Snapback",
		mappedCollection: "community",
		mappedValue: "Cobe_Snapback.png",
	},
]

export const DuotoneMapping = {
	3621: "Blue",
	1640: "Ultimate Grey",
	4415: "Champagne",
	4390: "White",
	1925: "White",
	1330: "White",
	1296: "Ultimate Grey",
	4120: "White",
	3145: "Champagne",
	1590: "Purple",
	3496: "Champagne",
	3258: "Orange",
	3981: "White",
	4485: "Yellow",
	1869: "Illuminating",
	3826: "Orange",
	3561: "Illuminating",
	782: "Illuminating",
	625: "Peri Tone",
	3606: "Ultimate Grey",
	2162: "White",
	2803: "Orange",
	2992: "Bubblegum",
	2836: "Bubblegum",
	3380: "Orange",
	1970: "Blue",
	2636: "Champagne",
	3374: "Orange",
	2840: "Computer Green",
	2530: "Bubblegum",
	2032: "White",
	710: "Bubblegum",
	2138: "Bubblegum",
	801: "White",
	4311: "Baby Blue",
	2844: "Baby Blue",
	2329: "White",
	4095: "Baby Blue",
	2169: "White",
	3385: "Baby Blue",
	964: "Ultimate Grey",
	1603: "Brown",
	1070: "White",
	3433: "Brown",
	3947: "Graphite",
	1382: "Blue",
	2683: "Graphite",
	2362: "Graphite",
	2827: "Graphite",
	1675: "Graphite",
	3640: "Pistachio",
	4471: "Pistachio",
	4647: "Navy",
	3247: "Blue",
	817: "Ultimate Grey",
	915: "Purple",
	2841: "Computer Green",
	3978: "Baby Blue",
	4341: "Ultimate Grey",
	2334: "Brown",
	1825: "Ultimate Grey",
	1102: "Pink",
	2176: "Brown",
	3799: "Baby Blue",
	3815: "Pistachio",
	3998: "Graphite",
	2208: "Baby Blue",
	4383: "Pistachio",
	1257: "Illuminating",
	3326: "Purple",
	3980: "Red",
	1573: "Bubblegum",
	4325: "Bubblegum",
	900: "Pink",
	1494: "Peri Tone",
	3800: "Bubblegum",
	3017: "Pink",
	1982: "White",
	1784: "Monster",
	949: "Ultimate Grey",
	4463: "Champagne",
	4045: "Navy",
	2635: "Champagne",
	1251: "White",
	2463: "White",
	2260: "Lime",
	3795: "Navy",
	3405: "Graphite",
	4014: "Pink",
	3614: "Navy",
	2359: "Orange",
	1205: "Red",
	3638: "Illuminating",
	3747: "Navy",
	2809: "White",
	618: "White",
	4617: "Navy",
	4283: "Ultimate Grey",
	3311: "Pistachio",
	1790: "Yellow",
	6688: "White",
	3887: "Ultimate Grey",
	1932: "Pink",
	3073: "Peri Tone",
	1347: "Ultimate Grey",
	2227: "White",
	1990: "Illuminating",
	1789: "Pink",
	2863: "Champagne",
	831: "Obsidian",
	1642: "Black",
	2833: "Champagne",
	1959: "Champagne",
	3728: "Champagne",
	3410: "White",
	1554: "Ultimate Grey",
	2080: "Pink",
	3610: "Illuminating",
	571: "White",
	3111: "Pistachio",
	1995: "Baby Blue",
	913: "Peri Tone",
	2309: "Lime",
	1328: "Ultimate Grey",
	809: "White",
	2475: "Champagne",
	1326: "Baby Blue",
	1306: "Baby Blue",
	570: "Ultimate Grey",
	3168: "Champagne",
	4094: "Pistachio",
	1471: "Ultimate Grey",
	1445: "Baby Blue",
	2917: "White",
	4421: "Computer Green",
	4540: "Brown",
	3548: "Ultimate Grey",
	2315: "White",
	2627: "White",
	2707: "Ultimate Grey",
	3924: "White",
	1992: "Baby Blue",
	912: "Computer Green",
	2440: "Baby Blue",
	3529: "Navy",
	4104: "Computer Green",
	3801: "Graphite",
	4513: "Pink",
	4313: "Graphite",
	2657: "Computer Green",
	1555: "Peri Tone",
	1449: "Graphite",
	2380: "Graphite",
	2858: "Graphite",
	4155: "Graphite",
	3394: "Ultimate Grey",
	4150: "Graphite",
	1815: "Peri Tone",
	3194: "Peri Tone",
	1183: "Graphite",
	2658: "Yellow",
	890: "White",
	4376: "White",
	2688: "Blue",
	2956: "White",
	2902: "Yellow",
	1272: "White",
	3575: "Computer Green",
	855: "Ultimate Grey",
	2390: "White",
	1650: "Champagne",
	4531: "Graphite",
	2771: "Ultimate Grey",
	4420: "Red",
	1112: "Graphite",
	789: "White",
	4577: "Champagne",
	1393: "White",
	1719: "Purple",
	1660: "Purple",
	3228: "White",
	2960: "Purple",
	4629: "Champagne",
	3150: "Ultimate Grey",
	4286: "Ultimate Grey",
	1786: "Purple",
	1290: "Illuminating",
	3102: "Illuminating",
	3545: "White",
	3897: "Navy",
	2269: "Navy",
	4110: "White",
	3613: "Ultimate Grey",
	2182: "Illuminating",
	699: "Purple",
	4321: "Illuminating",
	4258: "Brown",
	2493: "Ultimate Grey",
	3002: "Ultimate Grey",
	1795: "White",
	1029: "Ultimate Grey",
	1026: "White",
	883: "Ultimate Grey",
	1544: "Ultimate Grey",
	1710: "Illuminating",
	2300: "Ultimate Grey",
	1496: "White",
	1763: "Peri Tone",
	1148: "White",
	4022: "White",
	4516: "Champagne",
	3007: "Champagne",
	2408: "Navy",
	3022: "White",
	3232: "Ultimate Grey",
	3617: "White",
	2172: "Ultimate Grey",
	2494: "White",
	2086: "White",
	2431: "Champagne",
	3951: "Pink",
	1343: "Navy",
	841: "Champagne",
	1268: "Red Camo",
	4210: "White",
	1316: "White",
	3767: "Champagne",
	2518: "Champagne",
	3190: "White",
	2292: "White",
	3768: "Champagne",
	4181: "Champagne",
	4004: "White",
	1047: "Champagne",
	1141: "Flare",
	4317: "White",
	4370: "Ultimate Grey",
	4124: "Navy",
	3636: "Graphite",
	4242: "Navy",
	3749: "Graphite",
	3904: "Graphite",
	1040: "Graphite",
	3231: "Peri Tone",
	3979: "Pistachio",
	4038: "Graphite",
	3829: "Graphite",
	2256: "Yellow",
	1517: "Computer Green",
	4049: "Champagne",
	3760: "Graphite",
	1881: "Orange",
	587: "Purple",
	560: "Baby Blue",
	942: "Graphite",
	3117: "Illuminating",
	1605: "White",
	3209: "White",
	4414: "Peri Tone",
	3557: "Red",
	2536: "White",
	1278: "Graphite",
	1785: "Illuminating",
	4498: "Graphite",
	4462: "Blue",
	2075: "Graphite",
	4040: "Blue",
	1207: "White",
	4268: "Navy",
	4524: "Champagne",
	2625: "White",
	1438: "Orange",
	4534: "Champagne",
	775: "Baby Blue",
	2228: "Champagne",
	3120: "Yellow",
	4649: "Champagne",
	1737: "White",
	874: "White",
	1381: "Champagne",
	4408: "Illuminating",
	3571: "White",
	3364: "Ultimate Grey",
	4263: "Navy",
	2311: "Snow",
	1474: "Ice",
	996: "Supergold",
	1714: "Water",
	2282: "Snow",
	2020: "Sky",
	2547: "Snow",
	2277: "Brown",
	1013: "Graphite",
	2867: "Baby Blue",
	3148: "Peri Tone",
	1198: "Graphite",
	2280: "Ultimate Grey",
	4308: "Baby Blue",
	1105: "Graphite",
	1217: "Pink",
	3237: "Graphite",
	754: "Red",
	687: "Graphite",
	1520: "Graphite",
	1819: "Orange",
	1180: "Baby Blue",
	1615: "Graphite",
	609: "Ultimate Grey",
	1059: "Ultimate Grey",
	1315: "Lime",
	830: "Red",
	2013: "Graphite",
	4515: "Peri Tone",
	1664: "Graphite",
	3391: "Graphite",
	2893: "Illuminating",
	1363: "Pink",
	1852: "Graphite",
	3195: "Red",
	2226: "Computer Green",
	3655: "Baby Blue",
	3165: "Graphite",
	1958: "Yellow",
	1848: "Blue",
	669: "Orange",
	659: "Graphite",
	1985: "Graphite",
	1060: "Brown",
	3305: "Computer Green",
	1366: "White",
	2642: "White",
	3668: "Orange",
	4329: "Ultimate Grey",
	1916: "Lime",
	880: "Graphite",
	4630: "Graphite",
	1623: "Graphite",
	2942: "Ultimate Grey",
	1628: "Ultimate Grey",
	2108: "Navy",
	1333: "Champagne",
	3213: "Champagne",
	2129: "Brown",
	696: "Baby Blue",
	4133: "Graphite",
	1865: "Pistachio",
	2583: "Ultimate Grey",
	4007: "Purple",
	2153: "Graphite",
	4212: "Champagne",
	2257: "Red",
	1435: "Navy",
	2423: "Lime",
}
