import React, { createContext } from "react"
import useTheme from "../hooks/useTheme"

const ThemeContext = createContext()

function ThemeProvider({ children }) {
	return (
		<ThemeContext.Provider value={useTheme()}>
			{children}
		</ThemeContext.Provider>
	)
}

export { ThemeContext, ThemeProvider }
