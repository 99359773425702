import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import Cookies from "js-cookie"
import { getSession } from "next-auth/react"
import { toast } from "react-hot-toast"

export const dropsApi = axios.create({
	baseURL: process.env.NEXT_PUBLIC_DJANGO_API_BASE_URL,
	timeout: 50000,
	withCredentials: true
})

// Handle request interceptor
dropsApi.interceptors.request.use(
	async config => {
		// Before each request check if there's a JWT token in the cookie
		const session = await getSession()
		if (session) {
			config.headers.Authorization = `Bearer ${session.access_token}`
		}

		// Get the CSRF token using js-cookie
		const csrfToken = Cookies.get("csrftoken")

		// If the CSRF token is found, and the request is not a GET request, set it in the headers
		if (csrfToken && config.method !== "get") {
			config.headers["X-CSRFToken"] = csrfToken
		}

		return config
	},
	error => Promise.reject(error)
)

export const systemApi = axios.create({
	baseURL: process.env.DJANGO_SYSTEM_BASE_URL, // Your Django REST API base url
	timeout: 50000 // Set a timeout limit
})

// Handle request interceptor
systemApi.interceptors.request.use(
	async config => {
		// Before each request check if there's a JWT token in the cookie
		const session = await getSession()
		if (session) {
			config.headers.Authorization = `Bearer ${session.access_token}`
		}
		return config
	},
	error => Promise.reject(error)
)

export const getIsMaintenanceModeOn = async () => {
	const djangoSystemUrl = process.env.DJANGO_SYSTEM_BASE_URL
	try {
		const response = await axios.get(`${djangoSystemUrl}/maintenance/status/`)

		if (response.status >= 200 && response.status < 300) {
			// Check for HTTP status 200 OK
			return response.data.is_active
		} else {
			console.error(
				"Unexpected response code when fetching maintenance mode status:",
				response.status
			)
			return true
		}
	} catch (error) {
		console.error("Error fetching maintenance mode status:", error)
		return true
	}
}

export function withToast(
	axiosCall: () => Promise<any>,
	toastMessage: string = "Success!"
) {
	return async function () {
		try {
			const result = await axiosCall()
			toast.success(toastMessage)
			return result
		} catch (error) {
			toast.error("An error occured!")
			throw error
		}
	}
}
