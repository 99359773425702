const duotoneGradientHapes = [
	{
		token_id: 2020,
		gradient: {
			horizontal: {
				coord_1: "rgba(92,188,202,255)",
				coord_2: "rgba(94,190,204,255)",
				coord_3: "rgba(109,199,210,255)"
			}
		}
	},
	{
		token_id: 996,
		gradient: {
			horizontal: {
				coord_1: "rgba(209,144,2,255)",
				coord_2: "rgba(208,146,1,255)",
				coord_3: "rgba(210,145,1,255)"
			}
		}
	},
	{
		token_id: 1714,
		gradient: {
			horizontal: {
				coord_1: "rgba(0,15,174,255)",
				coord_2: "rgba(0,18,180,255)",
				coord_3: "rgba(0,30,205,255)"
			},
			vertical: {
				coord_1: "rgba(0,37,214,255)",
				coord_2: "rgba(0,30,205,255)"
			}
		}
	},
	{
		token_id: 4577,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 4133,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3405,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3887,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2277,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 3947,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2162,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2833,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 4268,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 3305,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 3168,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3259,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 4317,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4014,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 1959,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 4210,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4104,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 3614,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 2658,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,156,12,255)",
				coord_2: "rgba(221,163,18,255)",
				coord_3: "rgba(232,186,42,255)"
			},
			vertical: {
				coord_1: "rgba(237,199,57,255)",
				coord_2: "rgba(232,186,42,255)"
			}
		}
	},
	{
		token_id: 1382,
		gradient: {
			horizontal: {
				coord_1: "rgba(0,51,137,255)",
				coord_2: "rgba(0,54,141,255)",
				coord_3: "rgba(0,72,168,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,72,168,255)"
			}
		}
	},
	{
		token_id: 3545,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2380,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1272,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1013,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2688,
		gradient: {
			horizontal: {
				coord_1: "rgba(0,51,137,255)",
				coord_2: "rgba(0,54,141,255)",
				coord_3: "rgba(0,72,168,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,72,168,255)"
			}
		}
	},
	{
		token_id: 2867,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 2300,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2329,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1865,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 3979,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 4094,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 2493,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1296,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2359,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 2583,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 4095,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 3148,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 1316,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1496,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1205,
		gradient: {
			horizontal: {
				coord_1: "rgba(149,0,1,255)",
				coord_2: "rgba(155,0,2,255)",
				coord_3: "rgba(184,0,4,255)"
			},
			vertical: {
				coord_1: "rgba(203,0,6,255)",
				coord_2: "rgba(184,0,4,255)"
			}
		}
	},
	{
		token_id: 2013,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3117,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 1198,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3799,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 1393,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1605,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2683,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3801,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 4007,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 4370,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2280,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 3111,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 2362,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1471,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 3209,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2228,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 4524,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3728,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 1445,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 2827,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1675,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 4120,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4513,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2917,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2625,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4515,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 4038,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(14,14,14,255)"
			},
			vertical: {
				coord_1: "rgba(16,16,16,255)",
				coord_2: "rgba(14,14,14,255)"
			}
		}
	},
	{
		token_id: 3640,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 3829,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2408,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 4421,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 3981,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3621,
		gradient: {
			horizontal: {
				coord_1: "rgba(0,51,137,255)",
				coord_2: "rgba(0,54,141,255)",
				coord_3: "rgba(0,72,168,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,72,168,255)"
			}
		}
	},
	{
		token_id: 4311,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 4471,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 3815,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 4308,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 3638,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 3767,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3120,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,156,12,255)",
				coord_2: "rgba(221,163,18,255)",
				coord_3: "rgba(232,186,42,255)"
			},
			vertical: {
				coord_1: "rgba(237,199,57,255)",
				coord_2: "rgba(232,186,42,255)"
			}
		}
	},
	{
		token_id: 4540,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 3575,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 1438,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 2260,
		gradient: {
			horizontal: {
				coord_1: "rgba(196,214,53,255)",
				coord_2: "rgba(200,216,60,255)",
				coord_3: "rgba(215,226,90,255)"
			},
			vertical: {
				coord_1: "rgba(223,230,109,255)",
				coord_2: "rgba(215,226,90,255)"
			}
		}
	},
	{
		token_id: 3145,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3022,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3232,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2803,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 2992,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,34,129,255)",
				coord_2: "rgba(223,41,135,255)",
				coord_3: "rgba(242,70,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,177,255)",
				coord_2: "rgba(242,70,161,255)"
			}
		}
	},
	{
		token_id: 1664,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1590,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 3548,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2960,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 4629,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3496,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 4534,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2153,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1105,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 4649,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3150,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 3747,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 4485,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,156,12,255)",
				coord_2: "rgba(221,163,18,255)",
				coord_3: "rgba(232,186,42,255)"
			},
			vertical: {
				coord_1: "rgba(237,199,57,255)",
				coord_2: "rgba(232,186,42,255)"
			}
		}
	},
	{
		token_id: 4414,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 3391,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 4212,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 1217,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,173,183,255)",
				coord_2: "rgba(219,185,194,255)",
				coord_3: "rgba(229,204,211,255)"
			},
			vertical: {
				coord_1: "rgba(228,202,209,255)",
				coord_2: "rgba(229,204,211,255)"
			}
		}
	},
	{
		token_id: 3897,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 1932,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 2315,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 855,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1640,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2390,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3684,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2627,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2257,
		gradient: {
			horizontal: {
				coord_1: "rgba(149,0,1,255)",
				coord_2: "rgba(155,0,2,255)",
				coord_3: "rgba(184,0,4,255)"
			},
			vertical: {
				coord_1: "rgba(203,0,6,255)",
				coord_2: "rgba(184,0,4,255)"
			}
		}
	},
	{
		token_id: 3237,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2518,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2707,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2269,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 3924,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3617,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1719,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 4286,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 3557,
		gradient: {
			horizontal: {
				coord_1: "rgba(149,0,1,255)",
				coord_2: "rgba(155,0,2,255)",
				coord_3: "rgba(184,0,4,255)"
			},
			vertical: {
				coord_1: "rgba(203,0,6,255)",
				coord_2: "rgba(184,0,4,255)"
			}
		}
	},
	{
		token_id: 3998,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3002,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1366,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2172,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2536,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2208,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 2642,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2615,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1435,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 2893,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 1763,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 2169,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2836,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,34,129,255)",
				coord_2: "rgba(223,41,135,255)",
				coord_3: "rgba(242,70,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,177,255)",
				coord_2: "rgba(242,70,161,255)"
			}
		}
	},
	{
		token_id: 2256,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,156,12,255)",
				coord_2: "rgba(221,163,18,255)",
				coord_3: "rgba(232,186,42,255)"
			},
			vertical: {
				coord_1: "rgba(237,199,57,255)",
				coord_2: "rgba(232,186,42,255)"
			}
		}
	},
	{
		token_id: 1995,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 1363,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 4647,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 1752,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2140,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 1381,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3410,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1278,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3380,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 1650,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3073,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 2844,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 3668,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 4349,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1554,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 3385,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 913,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 1916,
		gradient: {
			horizontal: {
				coord_1: "rgba(196,214,53,255)",
				coord_2: "rgba(200,216,60,255)",
				coord_3: "rgba(215,226,90,255)"
			},
			vertical: {
				coord_1: "rgba(223,230,109,255)",
				coord_2: "rgba(215,226,90,255)"
			}
		}
	},
	{
		token_id: 1347,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 2956,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 880,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1786,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 2309,
		gradient: {
			horizontal: {
				coord_1: "rgba(196,214,53,255)",
				coord_2: "rgba(200,216,60,255)",
				coord_3: "rgba(215,226,90,255)"
			},
			vertical: {
				coord_1: "rgba(223,230,109,255)",
				coord_2: "rgba(215,226,90,255)"
			}
		}
	},
	{
		token_id: 1970,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 3274,
		gradient: {
			horizontal: {
				coord_1: "rgba(0,51,137,255)",
				coord_2: "rgba(0,54,141,255)",
				coord_3: "rgba(0,72,168,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,72,168,255)"
			}
		}
	},
	{
		token_id: 1517,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 3795,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 890,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 964,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1660,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 4531,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3195,
		gradient: {
			horizontal: {
				coord_1: "rgba(149,0,1,255)",
				coord_2: "rgba(155,0,2,255)",
				coord_3: "rgba(184,0,4,255)"
			},
			vertical: {
				coord_1: "rgba(203,0,6,255)",
				coord_2: "rgba(184,0,4,255)"
			}
		}
	},
	{
		token_id: 4630,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 817,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 4124,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 2227,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1623,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1603,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 2809,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 775,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 4049,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2942,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1290,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 4383,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 1148,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2636,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2080,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 1628,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 4022,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1992,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 2108,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 949,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1869,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 2226,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 1328,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 915,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 3760,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3655,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 618,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3190,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4463,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(63,61,58,255)"
			},
			vertical: {
				coord_1: "rgba(65,63,60,255)",
				coord_2: "rgba(63,61,58,255)"
			}
		}
	},
	{
		token_id: 3826,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 3636,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1257,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 4242,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 3326,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 1881,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 3374,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 587,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 1785,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 2858,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2841,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 3610,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 3165,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2840,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 754,
		gradient: {
			horizontal: {
				coord_1: "rgba(149,0,1,255)",
				coord_2: "rgba(155,0,2,255)",
				coord_3: "rgba(184,0,4,255)"
			},
			vertical: {
				coord_1: "rgba(203,0,6,255)",
				coord_2: "rgba(184,0,4,255)"
			}
		}
	},
	{
		token_id: 4498,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 4045,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 4110,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 687,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 571,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2292,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4313,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 4462,
		gradient: {
			horizontal: {
				coord_1: "rgba(0,51,137,255)",
				coord_2: "rgba(0,54,141,255)",
				coord_3: "rgba(0,72,168,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,72,168,255)"
			}
		}
	},
	{
		token_id: 4415,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2771,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 4617,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(14,16,25,255)"
			},
			vertical: {
				coord_1: "rgba(17,19,29,255)",
				coord_2: "rgba(14,16,25,255)"
			}
		}
	},
	{
		token_id: 4376,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4155,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2530,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,34,129,255)",
				coord_2: "rgba(223,41,135,255)",
				coord_3: "rgba(242,70,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,177,255)",
				coord_2: "rgba(242,70,161,255)"
			}
		}
	},
	{
		token_id: 3561,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 4408,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 4390,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3980,
		gradient: {
			horizontal: {
				coord_1: "rgba(149,0,1,255)",
				coord_2: "rgba(155,0,2,255)",
				coord_3: "rgba(184,0,4,255)"
			},
			vertical: {
				coord_1: "rgba(203,0,6,255)",
				coord_2: "rgba(184,0,4,255)"
			}
		}
	},
	{
		token_id: 2902,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,156,12,255)",
				coord_2: "rgba(221,163,18,255)",
				coord_3: "rgba(232,186,42,255)"
			},
			vertical: {
				coord_1: "rgba(237,199,57,255)",
				coord_2: "rgba(232,186,42,255)"
			}
		}
	},
	{
		token_id: 3228,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3768,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3749,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3978,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 1573,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,34,129,255)",
				coord_2: "rgba(223,41,135,255)",
				coord_3: "rgba(242,70,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,177,255)",
				coord_2: "rgba(242,70,161,255)"
			}
		}
	},
	{
		token_id: 4325,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,34,129,255)",
				coord_2: "rgba(223,41,135,255)",
				coord_3: "rgba(242,70,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,177,255)",
				coord_2: "rgba(242,70,161,255)"
			}
		}
	},
	{
		token_id: 4516,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 4283,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 3394,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 3007,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3904,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2399,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 912,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 1040,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 900,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 1494,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 2423,
		gradient: {
			horizontal: {
				coord_1: "rgba(196,214,53,255)",
				coord_2: "rgba(200,216,60,255)",
				coord_3: "rgba(215,226,90,255)"
			},
			vertical: {
				coord_1: "rgba(223,230,109,255)",
				coord_2: "rgba(215,226,90,255)"
			}
		}
	},
	{
		token_id: 1333,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 782,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 2635,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 4150,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(14,14,14,255)"
			},
			vertical: {
				coord_1: "rgba(16,16,16,255)",
				coord_2: "rgba(14,14,14,255)"
			}
		}
	},
	{
		token_id: 3800,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,34,129,255)",
				coord_2: "rgba(223,41,135,255)",
				coord_3: "rgba(242,70,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,177,255)",
				coord_2: "rgba(242,70,161,255)"
			}
		}
	},
	{
		token_id: 3213,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3613,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 3102,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 1925,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2494,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2086,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1795,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1251,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3231,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 2440,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 1958,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,156,12,255)",
				coord_2: "rgba(221,163,18,255)",
				coord_3: "rgba(232,186,42,255)"
			},
			vertical: {
				coord_1: "rgba(237,199,57,255)",
				coord_2: "rgba(232,186,42,255)"
			}
		}
	},
	{
		token_id: 626,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 2032,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1070,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2182,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 2431,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 4541,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1990,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 3017,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 699,
		gradient: {
			horizontal: {
				coord_1: "rgba(73,9,134,255)",
				coord_2: "rgba(78,12,139,255)",
				coord_3: "rgba(102,20,165,255)"
			},
			vertical: {
				coord_1: "rgba(120,26,181,255)",
				coord_2: "rgba(102,20,165,255)"
			}
		}
	},
	{
		token_id: 809,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3311,
		gradient: {
			horizontal: {
				coord_1: "rgba(156,213,145,255)",
				coord_2: "rgba(162,215,151,255)",
				coord_3: "rgba(187,225,176,255)"
			},
			vertical: {
				coord_1: "rgba(199,230,190,255)",
				coord_2: "rgba(187,225,176,255)"
			}
		}
	},
	{
		token_id: 3571,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4181,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2463,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 2657,
		gradient: {
			horizontal: {
				coord_1: "rgba(19,183,32,255)",
				coord_2: "rgba(30,188,38,255)",
				coord_3: "rgba(87,206,62,255)"
			},
			vertical: {
				coord_1: "rgba(115,216,80,255)",
				coord_2: "rgba(87,206,62,255)"
			}
		}
	},
	{
		token_id: 1413,
		gradient: {
			horizontal: {
				coord_1: "rgba(196,214,53,255)",
				coord_2: "rgba(200,216,60,255)",
				coord_3: "rgba(215,226,90,255)"
			},
			vertical: {
				coord_1: "rgba(223,230,109,255)",
				coord_2: "rgba(215,226,90,255)"
			}
		}
	},
	{
		token_id: 625,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 4004,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1520,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1737,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3951,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 1815,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 4341,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1790,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,156,12,255)",
				coord_2: "rgba(221,163,18,255)",
				coord_3: "rgba(232,186,42,255)"
			},
			vertical: {
				coord_1: "rgba(237,199,57,255)",
				coord_2: "rgba(232,186,42,255)"
			}
		}
	},
	{
		token_id: 2334,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 3194,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 1047,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 3364,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1819,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 4321,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 1183,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 3258,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 1555,
		gradient: {
			horizontal: {
				coord_1: "rgba(77,78,158,255)",
				coord_2: "rgba(81,82,164,255)",
				coord_3: "rgba(107,106,190,255)"
			},
			vertical: {
				coord_1: "rgba(126,125,205,255)",
				coord_2: "rgba(107,106,190,255)"
			}
		}
	},
	{
		token_id: 4420,
		gradient: {
			horizontal: {
				coord_1: "rgba(149,0,1,255)",
				coord_2: "rgba(155,0,2,255)",
				coord_3: "rgba(184,0,4,255)"
			},
			vertical: {
				coord_1: "rgba(203,0,6,255)",
				coord_2: "rgba(184,0,4,255)"
			}
		}
	},
	{
		token_id: 1982,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4263,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 2129,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 1112,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2475,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 1343,
		gradient: {
			horizontal: {
				coord_1: "rgba(27,33,57,255)",
				coord_2: "rgba(29,35,61,255)",
				coord_3: "rgba(40,47,78,255)"
			},
			vertical: {
				coord_1: "rgba(50,58,94,255)",
				coord_2: "rgba(40,47,78,255)"
			}
		}
	},
	{
		token_id: 1789,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 1029,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1026,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1326,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 841,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 2075,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1848,
		gradient: {
			horizontal: {
				coord_1: "rgba(0,51,137,255)",
				coord_2: "rgba(0,54,141,255)",
				coord_3: "rgba(0,72,168,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,72,168,255)"
			}
		}
	},
	{
		token_id: 2863,
		gradient: {
			horizontal: {
				coord_1: "rgba(212,200,182,255)",
				coord_2: "rgba(214,203,186,255)",
				coord_3: "rgba(224,216,204,255)"
			},
			vertical: {
				coord_1: "rgba(229,223,213,255)",
				coord_2: "rgba(224,216,204,255)"
			}
		}
	},
	{
		token_id: 1306,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 1180,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 669,
		gradient: {
			horizontal: {
				coord_1: "rgba(216,86,4,255)",
				coord_2: "rgba(221,93,7,255)",
				coord_3: "rgba(238,120,18,255)"
			},
			vertical: {
				coord_1: "rgba(245,138,27,255)",
				coord_2: "rgba(238,120,18,255)"
			}
		}
	},
	{
		token_id: 789,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 659,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1825,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 696,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 883,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1449,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1330,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1544,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 710,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,34,129,255)",
				coord_2: "rgba(223,41,135,255)",
				coord_3: "rgba(242,70,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,177,255)",
				coord_2: "rgba(242,70,161,255)"
			}
		}
	},
	{
		token_id: 874,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 1615,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1710,
		gradient: {
			horizontal: {
				coord_1: "rgba(211,200,90,255)",
				coord_2: "rgba(214,203,97,255)",
				coord_3: "rgba(225,216,124,255)"
			},
			vertical: {
				coord_1: "rgba(230,224,142,255)",
				coord_2: "rgba(225,216,124,255)"
			}
		}
	},
	{
		token_id: 4040,
		gradient: {
			horizontal: {
				coord_1: "rgba(0,51,137,255)",
				coord_2: "rgba(0,54,141,255)",
				coord_3: "rgba(0,72,168,255)"
			},
			vertical: {
				coord_1: "rgba(0,86,184,255)",
				coord_2: "rgba(0,72,168,255)"
			}
		}
	},
	{
		token_id: 609,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 560,
		gradient: {
			horizontal: {
				coord_1: "rgba(109,202,213,255)",
				coord_2: "rgba(118,205,215,255)",
				coord_3: "rgba(154,217,225,255)"
			},
			vertical: {
				coord_1: "rgba(173,225,230,255)",
				coord_2: "rgba(154,217,225,255)"
			}
		}
	},
	{
		token_id: 2138,
		gradient: {
			horizontal: {
				coord_1: "rgba(218,34,129,255)",
				coord_2: "rgba(223,41,135,255)",
				coord_3: "rgba(242,70,161,255)"
			},
			vertical: {
				coord_1: "rgba(250,90,177,255)",
				coord_2: "rgba(242,70,161,255)"
			}
		}
	},
	{
		token_id: 1985,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 1102,
		gradient: {
			horizontal: {
				coord_1: "rgba(213,174,184,255)",
				coord_2: "rgba(215,179,188,255)",
				coord_3: "rgba(225,198,205,255)"
			},
			vertical: {
				coord_1: "rgba(230,208,214,255)",
				coord_2: "rgba(225,198,205,255)"
			}
		}
	},
	{
		token_id: 1059,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 570,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 1312,
		gradient: {
			horizontal: {
				coord_1: "rgba(196,214,53,255)",
				coord_2: "rgba(200,216,60,255)",
				coord_3: "rgba(215,226,90,255)"
			},
			vertical: {
				coord_1: "rgba(223,230,109,255)",
				coord_2: "rgba(215,226,90,255)"
			}
		}
	},
	{
		token_id: 1060,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 942,
		gradient: {
			horizontal: {
				coord_1: "rgba(26,26,26,255)",
				coord_2: "rgba(28,28,28,255)",
				coord_3: "rgba(40,40,40,255)"
			},
			vertical: {
				coord_1: "rgba(48,48,48,255)",
				coord_2: "rgba(40,40,40,255)"
			}
		}
	},
	{
		token_id: 2176,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 3433,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 801,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 4258,
		gradient: {
			horizontal: {
				coord_1: "rgba(53,23,1,255)",
				coord_2: "rgba(57,25,1,255)",
				coord_3: "rgba(72,34,2,255)"
			},
			vertical: {
				coord_1: "rgba(86,43,3,255)",
				coord_2: "rgba(72,34,2,255)"
			}
		}
	},
	{
		token_id: 1207,
		gradient: {
			horizontal: {
				coord_1: "rgba(201,202,202,255)",
				coord_2: "rgba(204,204,204,255)",
				coord_3: "rgba(216,216,216,255)"
			},
			vertical: {
				coord_1: "rgba(224,224,224,255)",
				coord_2: "rgba(216,216,216,255)"
			}
		}
	},
	{
		token_id: 3606,
		gradient: {
			horizontal: {
				coord_1: "rgba(132,133,135,255)",
				coord_2: "rgba(138,139,141,255)",
				coord_3: "rgba(163,164,166,255)"
			},
			vertical: {
				coord_1: "rgba(178,179,181,255)",
				coord_2: "rgba(163,164,166,255)"
			}
		}
	},
	{
		token_id: 830,
		gradient: {
			horizontal: {
				coord_1: "rgba(149,0,1,255)",
				coord_2: "rgba(155,0,2,255)",
				coord_3: "rgba(184,0,4,255)"
			},
			vertical: {
				coord_1: "rgba(203,0,6,255)",
				coord_2: "rgba(184,0,4,255)"
			}
		}
	}
]

export default duotoneGradientHapes
