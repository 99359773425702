import "../styles/global/index.scss"
import "../styles/progress.css"
import "../styles/fonts.css"

import React, { useContext, useEffect } from "react"
import { ThemeProvider } from "../src/contexts/ThemeContext"
import { DarkModeProvider } from "../src/contexts/DarkModeContext"
import { WalletProvider } from "../src/contexts/WalletContext"
import { ModalProvider } from "../src/contexts/ModalContext"
import { ToastProvider } from "react-toast-notifications"
import { ChakraProvider } from "@chakra-ui/react"
import Script from "next/script"
import AdminCmsTheme from "../src/components/AdminCms/Layout/Theme"
import { Toaster } from "react-hot-toast"
import { useRouter } from "next/router"
import { SessionProvider } from "next-auth/react"
import { SystemProvider } from "../src/contexts/SystemContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

const { NEXT_PUBLIC_GOOGLE_ANALYTICS } = process.env
function MyApp({ Component, pageProps: { session, ...pageProps } }) {
	const getLayout = Component.getLayout || ((page) => page)

	const router = useRouter()
	// Check if the current route starts with /cms
	const isCMSRoute = router.asPath.startsWith("/cms")
	const queryClient = new QueryClient()
	// Apply a CSS class to the body depending on the current route
	useEffect(() => {
		document.body.className = isCMSRoute ? "cms-body" : ""
	}, [router.asPath]) // Rerun this effect every time the route changes

	return getLayout(
		<>
			<Script
				strategy="lazyOnload"
				src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
			/>

			<Script id="loadGoogle" strategy="lazyOnload">
				{`
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '${NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
		page_path: window.location.pathname,
		});
	`}
			</Script>
			<QueryClientProvider client={queryClient}>
				<SessionProvider session={session}>
					<SystemProvider>
						<ModalProvider>
							<WalletProvider>
								<DarkModeProvider>
									<ThemeProvider>
										<Toaster position="top-center" />
										<ToastProvider autoDismiss={true} autoDismissTimeout={4000}>
											<ChakraProvider theme={AdminCmsTheme}>
												<Component {...pageProps} />
											</ChakraProvider>
										</ToastProvider>
									</ThemeProvider>
								</DarkModeProvider>
							</WalletProvider>
						</ModalProvider>
					</SystemProvider>
				</SessionProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</>,
	)
}

export default MyApp
