import { extendTheme } from "@chakra-ui/react"
const AdminCmsTheme = extendTheme({
	config: {
		initialColorMode: "dark",
		useSystemColorMode: false
	},
	styles: {
		global: {
			body: {
				bg: "#202020",
				fontFamily: "Konnect"
			},
			span: {
				display: "inline-block",
				verticalAlign: "middle"
			},
			img: {
				maxWidth: "none"
			}
		}
	},
	components: {
		Checkbox: {
			baseStyle: {
				control: {
					_checked: {
						bg: "white",
						color: "white",
						borderColor: "white",
						_hover: {
							bg: "white",
							borderColor: "white"
						},
						_focus: {
							boxShadow: "none"
						}
					},
					_indeterminate: {
						bg: "white",
						color: "white",
						borderColor: "white"
					}
				}
			}
		}
	}
})

export default AdminCmsTheme
