import React, { createContext, Dispatch, SetStateAction } from "react"
import useWallet from "../hooks/useWallet"

interface IWalletContext {
	web3Provider: any
	equipment: any
	equipmentSignature: string
	connect?: () => Promise<void>
	disconnect?: () => Promise<void>
	signToGetEquipment?: (hapeTokenId: number) => Promise<void>
	signToEarlyAccess?: () => Promise<boolean>
	signToGetDynamicEquipment?: (hapeTokenId: number) => Promise<any>
	getFormattedAddress?: () => string
	checkOwnerOf?: (hapeTokenId: number) => Promise<void>
	isSigning: boolean
	hasSigned: boolean
	unSign?: () => void
	isGettingEquipment: boolean
	isAddressOwnerOf: boolean
	isCheckingOwnerOf: boolean
	account: string
	dynamicEquipment: any[] | null
	signToAuthenticateWalletLegacy: () => Promise<void>
}

const defaultState = {
	web3Provider: null,
	equipment: null,
	equipmentSignature: null,
	isSigning: false,
	hasSigned: false,
	isGettingEquipment: false,
	isAddressOwnerOf: false,
	isCheckingOwnerOf: false,
	account: null,
	dynamicEquipment: null,
	signToAuthenticateWalletLegacy: null,
}

const WalletContext = createContext<IWalletContext>(defaultState)

function WalletProvider({ children }) {
	return (
		<WalletContext.Provider value={useWallet()}>
			{children}
		</WalletContext.Provider>
	)
}

export { WalletContext, WalletProvider }
