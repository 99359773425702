import { Network } from "alchemy-sdk"

const { USE_MAINNET } = process.env

export const tabletWidth = 1000
export const mobileWidth = 500

export const ItemType = {
	Accessory: "Accessory",
	Clothing: "Clothing",
	Earrings: "Earrings",
	Eyewear: "Eyewear",
	"Face Addition": "Face Addition",
	Gadget: "Gadget",
	Headwear: "Headwear",
	Jewellery: "Jewellery",
	"Nose Piercing": "Nose Piercing",
	Teeth: "Teeth",
	Wristband: "Wristband",
	Badge: "Badge",
}

export enum BadgeTypes {
	Community,
	Normal,
	Rare,
	Legendary,
	Genesis,
}
export const isolatedHapesAwsUrl = "https://dpqmbay1docqc.cloudfront.net"
export const repoDropsAwsUrl = "https://d1krl52y1om3ch.cloudfront.net/"

const baseBadgedHapeUrl = "https://d3dmfdsqhq2hcd.cloudfront.net"

export const HAPE_OR_NOT_POINTS_PER_VOTE = 2
export const MAX_VOTES = 60

export const itemNames = [
	{
		blockchainName: "HAPE NFT NYC Holder Wristband",
		folderName: "nft-nyc",
		fileName: "holder_wristband",
		display: "NFT.NYC Holder",
		multiplier: 20,
		rarityType: "legendary",
	},
	{
		blockchainName: "HAPE NFT NYC VIP Wristband",
		folderName: "nft-nyc",
		fileName: "vip_wristband",
		display: "NFT.NYC VIP",
		multiplier: 25,
		rarityType: "mythic",
	},
	{
		blockchainName: "HAPE NFT NYC Attendee Wristband",
		folderName: "nft-nyc",
		fileName: "attendee_wristband",
		display: "NFT.NYC Attendee",
		multiplier: 10,
		rarityType: "epic",
	},
	{
		blockchainName: "HAPE MET AMS Attendee Wristband",
		folderName: "ams-met",
		fileName: "attendee_wristband",
		display: "MET AMS Attendee",
		multiplier: 10,
		rarityType: "epic",
	},
	{
		blockchainName: "HAPE MET AMS Holder Wristband",
		folderName: "ams-met",
		fileName: "holder_wristband",
		display: "MET AMS Holder",
		multiplier: 20,
		rarityType: "legendary",
	},
	{
		blockchainName: "HAPE MET AMS VIP Wristband",
		folderName: "ams-met",
		fileName: "vip_wristband",
		display: "MET AMS VIP",
		multiplier: 25,
		rarityType: "mythic",
	},
	{
		blockchainName: "HAPE Community Badge",
		folderName: "airdrop-1",
		fileName: "community_badge",
		display: "HAPE Community Badge",
		animated: {
			folderName: "badge_animated",
			fileName: "community",
		},
		multiplier: 5,
		baseAwsUrl: `${baseBadgedHapeUrl}/community`,
		exportAwsUrl: `${baseBadgedHapeUrl}/export/community`,
		rarityType: "common",
	},
	{
		blockchainName: "HAPE Holder Badge",
		folderName: "airdrop-1",
		fileName: "common_badge",
		display: "HAPE Holder Badge",
		animated: {
			folderName: "badge_animated",
			fileName: "common",
		},
		multiplier: 15,
		baseAwsUrl: `${baseBadgedHapeUrl}/common`,
		exportAwsUrl: `${baseBadgedHapeUrl}/export/common`,
		rarityType: "rare",
	},
	{
		blockchainName: "HAPE Holder Badge (Rare)",
		folderName: "airdrop-1",
		fileName: "rare_badge",
		display: "HAPE Holder Badge (Rare)",
		animated: {
			folderName: "badge_animated",
			fileName: "rare",
		},
		multiplier: 20,
		baseAwsUrl: `${baseBadgedHapeUrl}/rare`,
		exportAwsUrl: `${baseBadgedHapeUrl}/export/rare/rare`,
		rarityType: "epic",
	},
	{
		blockchainName: "HAPE Holder Badge (Legendary)",
		folderName: "airdrop-1",
		fileName: "legendary_badge",
		display: "HAPE Holder Badge (Legendary)",
		animated: {
			folderName: "badge_animated",
			fileName: "legendary",
		},
		multiplier: 25,
		baseAwsUrl: `${baseBadgedHapeUrl}/legendary`,
		exportAwsUrl: `${baseBadgedHapeUrl}/export/legendary`,
		rarityType: "legendary",
	},
	{
		blockchainName: "HAPE Genesis Badge",
		folderName: "airdrop-1",
		fileName: "genesis_badge",
		display: "HAPE Genesis Badge",
		animated: {
			folderName: "badge_animated",
			fileName: "genesis",
		},
		multiplier: 30,
		baseAwsUrl: `${baseBadgedHapeUrl}`,
		exportAwsUrl: `${baseBadgedHapeUrl}/export/genesis/genesis`,
		rarityType: "mythic",
	},
	// {
	// 	blockchainName: "Ghost #4808",
	// 	folderName: "ams-met",
	// 	fileName: "holder_wristband",
	// 	display: "MET AMS Holder",
	// 	multiplier: 0.2,
	// 	baseAwsUrl: ""
	// }
]

const bonusPoints = [
	{
		lower: 1,
		upper: 2,
		bonusPoints: 30,
	},
	{
		lower: 3,
		upper: 4,
		bonusPoints: 25,
	},
	{
		lower: 5,
		upper: 6,
		bonusPoints: 20,
	},
	{
		lower: 7,
		upper: 30,
		bonusPoints: 15,
	},
	{
		lower: 31,
		upper: 100,
		bonusPoints: 10,
	},
]

const bonusPercentage = [
	{
		rank: 1,
		bonusPercentage: 5,
	},
	{
		rank: 2,
		bonusPercentage: 4.5,
	},
	{
		rank: 3,
		bonusPercentage: 4,
	},
	{
		rank: 4,
		bonusPercentage: 3.5,
	},
	{
		rank: 5,
		bonusPercentage: 3,
	},
	{
		rank: 6,
		bonusPercentage: 2.5,
	},
	{
		rank: 7,
		bonusPercentage: 2,
	},
	{
		rank: 8,
		bonusPercentage: 1.5,
	},
	{
		rank: 9,
		bonusPercentage: 1,
	},
	{
		rank: 10,
		bonusPercentage: 0.5,
	},
]

export function getWeeklyBonuses(rank: number) {
	const points = bonusPoints.find(
		(b) => rank >= b.lower && rank <= b.upper,
	).bonusPoints

	const temp = bonusPercentage.find((b) => b.rank === rank)
	const percentage = temp ? temp.bonusPercentage : 0

	return {
		bonusPoints: points,
		bonusPercentage: percentage,
	}
}

//export const TestNet = Network.ETH_GOERLI
export const TestNet = Network.ETH_SEPOLIA

export function getEthNetwork() {
	return USE_MAINNET === "true" ? Network.ETH_MAINNET : TestNet
}

export function getEthNetworkEthersjs() {
	return USE_MAINNET === "true" ? "homestead" : "sepolia"
}

export const getEquipmentFolder = (itemType) => {
	if (itemType === ItemType.Badge) {
		return "badges"
	} else if (itemType === ItemType.Wristband) {
		return "wristbands"
	}
}

export function getTierStandaradColor(tier: string = "COMMON") {
	const option = tier.toUpperCase()
	const colors = {
		COMMON: "#7E7E7E",
		UNCOMMON: "#75F149",
		RARE: "#17C0E6",
		LEGENDARY: "#F19A49",
		MYTHIC: "#D71717",
		EPIC: "#D71717",
		SUPER: "#D71717",
	}

	return colors[option] ?? colors["COMMON"]
}

export const getEquipmentObject = (blockchainName) => {
	return itemNames.find((w) => w.blockchainName === blockchainName)
}

export const walletMessage =
	"Sign this one-time nonce to verify ownership of this HAPE Prime. \n\nVerify the origin is from https://index.hape.io before signing. \n\n"

export const nftNycBadgeName = [
	"HAPE NFT NYC Holder Wristband",
	"HAPE NFT NYC Attendee Wristband",
	"HAPE NFT NYC VIP Wristband",
]

export const dynamicClothingThumbnailsUri =
	"https://d5fcg745fuvkn.cloudfront.net"
export const tempFoundationBoxDynamicClothingThumbnailsUri =
	"https://d3hlh47f6b82m7.cloudfront.net"

//TestNet
// export const hapeBadgeContractAddress =
// 	"0xF60d3EC7f4a15bCA86EB943356737d0AAB94e953".toLowerCase()

// export const hapePrimeContractAddress =
// 	"0xfBC64733b9bB45C5793f4f340a7Fc71e95874057".toLowerCase()

// export const nftNycWristbandContractAddress =
// 	"0x5941cCAF26cc637C6eFB26eeFBa9B24d4db5D438".toLowerCase()

// export const metAmsWristbandContractAddress =
// 	"0x160c404b2b49cbc3240055ceaee026df1e8497a0".toLowerCase()

// export const testNetClothingContractAddress =
// 	"0x60e0e862d23da5480d33939def87cc349a2a5fb9"

// Testnet

export const MarketplaceContractAddressTestnet =
	"0x60e0e862d23da5480d33939def87cc349a2a5fb9".toLowerCase()

export const ClaimedFoundationBoxContractAddressTestNet =
	"0xeeb76f13812dec401be64a66183d5a29dbf13006".toLowerCase()

export const UnclaimedFoundationBoxContractAddressTestNet =
	"0x55b4c2d09a31954f50ebb75d977e30345017d3fc".toLowerCase()

export const DressXCostumesContractAddressesTestNet = [
	"0x932ca55b9ef0b3094e8fa82435b3b4c50d713043".toLowerCase(),
]

export const ClothingContractAddressesTestNet = [
	MarketplaceContractAddressTestnet,
	ClaimedFoundationBoxContractAddressTestNet,
	UnclaimedFoundationBoxContractAddressTestNet,
	...DressXCostumesContractAddressesTestNet,
]

//Mainnet

export const MarketplaceContractAddress =
	"0x1b35127c286b3e6e547da8525752f5d57d526bbf".toLowerCase()

export const UnclaimedFoundationBoxContractAddress =
	"0x40c105fe3070b0ba6786a16440347339f73ddddc".toLowerCase()

export const ClaimedFoundationBoxContractAddress =
	"0x6aaf76eb0101010b8f20fa41ea2886a0bd0eae45".toLowerCase()

export const DressXVioletWavesCostumeContractAddress =
	"0x27C03bA117406C5B5291907fc7fD0B85FA0B21ad".toLowerCase()
export const DressXOrangeCoordCostumeContractAddress =
	"0xe831286BB3b6A08ED38D379514a57482391D9e1f".toLowerCase()
export const DressXBlueSplatterCoordCostumeContractAddress =
	"0x6B5ADaF48FEaDA1f10b1Ff62C4249A6b2519e6B7".toLowerCase()
export const DressXBlackChainLinkCoordCostumeContractAddress =
	"0x8ABe8A346f8910d30E17D3466eE842658A6Facc8".toLowerCase()
export const DressXReflectiveWatercolourCoordCostumeContractAddress =
	"0xEB15d6d1C1FE58a6C087eb2FA2Fa73ae347599AC".toLowerCase()
export const DressXReflectiveNeonCostumeContractAddress =
	"0x64156bd96da9835AB30cB7ab667B7F1A8fD22D32".toLowerCase()

export const DressXCostumeToContractAddressMapping = {
	"Violet Waves": DressXVioletWavesCostumeContractAddress,
	"Orange DX Co-ord": DressXOrangeCoordCostumeContractAddress,
	"Blue DX Splatter Co-ord": DressXBlueSplatterCoordCostumeContractAddress,
	"Black Chain-Link Co-ord": DressXBlackChainLinkCoordCostumeContractAddress,
	"Reflective Watercolour Co-ord":
		DressXReflectiveWatercolourCoordCostumeContractAddress,
	"Reflective Neon": DressXReflectiveNeonCostumeContractAddress,
}

//TODO  make sure these are right
export const DressXCostumesContractAddresses = [
	DressXOrangeCoordCostumeContractAddress,
	DressXVioletWavesCostumeContractAddress,
	DressXBlueSplatterCoordCostumeContractAddress,
	DressXBlackChainLinkCoordCostumeContractAddress,
	DressXReflectiveWatercolourCoordCostumeContractAddress,
	DressXReflectiveNeonCostumeContractAddress,
]

export const clothingContractAddresses = [
	MarketplaceContractAddress,
	UnclaimedFoundationBoxContractAddress,
	ClaimedFoundationBoxContractAddress,
	...DressXCostumesContractAddresses,
]

export const hapeBadgeContractAddress =
	"0x6fa9f4b50e2950a8137a76649193816fb29dad2c"

export const hapePrimeContractAddress =
	"0x4db1f25d3d98600140dfc18deb7515be5bd293af"

export const nftNycWristbandContractAddress =
	"0x7c1124c2bdc3fb3b3036a2c14429e5a232ed2793"

export const metAmsWristbandContractAddress =
	"0x4944745f84c4eae5516b13636bc3a02a9ebc2831"

export const allHapeContractAddresses = [
	hapeBadgeContractAddress,
	hapePrimeContractAddress,
	nftNycWristbandContractAddress,
	metAmsWristbandContractAddress,
]

export const allWristbandContractAddresses = [
	metAmsWristbandContractAddress,
	nftNycWristbandContractAddress,
]

export const hapePrimeContractAbi = [
	{
		inputs: [
			{ internalType: "address", name: "metadataAddress", type: "address" },
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "owner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "approved",
				type: "address",
			},
			{
				indexed: true,
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
		],
		name: "Approval",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "owner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "operator",
				type: "address",
			},
			{ indexed: false, internalType: "bool", name: "approved", type: "bool" },
		],
		name: "ApprovalForAll",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: "address", name: "from", type: "address" },
			{ indexed: true, internalType: "address", name: "to", type: "address" },
			{
				indexed: true,
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
		],
		name: "Transfer",
		type: "event",
	},
	{
		inputs: [
			{ internalType: "address", name: "to", type: "address" },
			{ internalType: "uint256", name: "tokenId", type: "uint256" },
		],
		name: "approve",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "owner", type: "address" }],
		name: "balanceOf",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
		name: "burn",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
		name: "getApproved",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "owner", type: "address" },
			{ internalType: "address", name: "operator", type: "address" },
		],
		name: "isApprovedForAll",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "isBurningActive",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "isMetadataLocked",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "isMinterLocked",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "isStartingIndexLocked",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "lockMetadata",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "lockMinter",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "metadata",
		outputs: [
			{
				internalType: "contract IHapebeastMetadata",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "uint256", name: "_count", type: "uint256" },
			{ internalType: "address", name: "_recipient", type: "address" },
		],
		name: "mint",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "minter",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "name",
		outputs: [{ internalType: "string", name: "", type: "string" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
		name: "ownerOf",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "provenanceHash",
		outputs: [{ internalType: "string", name: "", type: "string" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "royaltyAddress",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "uint256", name: "tokenId", type: "uint256" },
			{ internalType: "uint256", name: "salePrice", type: "uint256" },
		],
		name: "royaltyInfo",
		outputs: [
			{ internalType: "address", name: "receiver", type: "address" },
			{ internalType: "uint256", name: "royaltyAmount", type: "uint256" },
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "royaltyPercent",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "from", type: "address" },
			{ internalType: "address", name: "to", type: "address" },
			{ internalType: "uint256", name: "tokenId", type: "uint256" },
		],
		name: "safeTransferFrom",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "from", type: "address" },
			{ internalType: "address", name: "to", type: "address" },
			{ internalType: "uint256", name: "tokenId", type: "uint256" },
			{ internalType: "bytes", name: "_data", type: "bytes" },
		],
		name: "safeTransferFrom",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "operator", type: "address" },
			{ internalType: "bool", name: "approved", type: "bool" },
		],
		name: "setApprovalForAll",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "string", name: "_provenanceHash", type: "string" },
		],
		name: "setProvenanceHash",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "uint256", name: "royaltyPercentage", type: "uint256" },
		],
		name: "setRoyaltyPercentage",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "royaltyReceiver", type: "address" },
		],
		name: "setRoyaltyReceiver",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "setStartingIndex",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "startingIndex",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
		name: "supportsInterface",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "symbol",
		outputs: [{ internalType: "string", name: "", type: "string" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "toggleBurningActive",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "tokenSupply",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
		name: "tokenURI",
		outputs: [{ internalType: "string", name: "", type: "string" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "totalSupply",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "from", type: "address" },
			{ internalType: "address", name: "to", type: "address" },
			{ internalType: "uint256", name: "tokenId", type: "uint256" },
		],
		name: "transferFrom",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "_metadata", type: "address" }],
		name: "updateMetadata",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "_minter", type: "address" }],
		name: "updateMinter",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "withdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
]

const NETWORK_MAINNET = {
	ETHEREUM: { name: "ETH", chainId: 1 },
	CMP: { name: "CMP", chainId: 256256 },
}

const NETWORK_TESTNET = {
	// ETHEREUM: { name: "GOERLI", chainId: 5 },
	ETHEREUM: { name: "ETH_SEPOLIA", chainId: 11155111 },
	CMP: { name: "CMP_TEST", chainId: 512512 },
}

export const networkMapping = {
	ETH_SEPOLIA: {
		displayName: "SEPOLIA ETH",
	},
	ETH: {
		displayName: "ETH",
	},
	CMP: {
		displayName: "CMP",
	},
	CMP_TEST: {
		displayName: "CMP_TEST",
	},
	GOERLI: {
		displayNAme: "GOERLI ETH",
	},
}

export const statusMapping = {
	AWAITING_PAYMENT: {
		displayName: "Processing Payment",
	},
}

export const NETWORK =
	USE_MAINNET && USE_MAINNET == "true" ? NETWORK_MAINNET : NETWORK_TESTNET
